import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ChooseLocation from "../views/ChooseLocation.vue";
import Test from "../views/Test.vue"
import Driver from "../views/driver.vue"
import TyremendUser from "../views/tyremenduser.vue"
import TyreMendDriver from "../views/tyremenddriver.vue"
import ExcavatorUser from "../views/excavatoruser.vue"
import ExcavatorDriver from "../views/excavatordriver.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: '/customer',
  },
  {
    path: "/customer",
    name: "Customer",
    component: Home,
  },
  {
    path: "/chooseLocation",
    name: "chooseLocation",
    component: ChooseLocation,
  },
  {
    path: "/driver",
    name: "Driver",
    component: Driver,
  },
  {
	//轮胎修补用户端订单webview
    path: "/tyremenduser",
    name: "TyremendUser",
    component: TyremendUser,
  },
  {
	//轮胎修补司机端订单webview
    path: "/tyremenddriver",
    name: "TyremendDriver",
    component: TyreMendDriver,
  },
  {
  	//挖机代班webview
    path: "/excavatoruser",
    name: "ExcavatorUser",
    component: ExcavatorUser,
  },
  {
  	//挖机代班webview
    path: "/excavatordriver",
    name: "ExcavatorDriver",
    component: ExcavatorDriver,
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
