<template>
	
	<div class="order-pay">
		<div class="alter-order-bottom" style="padding: 0px;background: transparent">
			<div class="pay-info">
				<img class="pay-img" src="../../assets/dui.png" alt="">
				<div class="order-complete"> 订单完成 </div>
				<div class="evaluate">
					<div class="evaluatesp" @click="evaluate">立即评价</div>
					<img class="evaluate-img" src="../../assets/pingjia.png" alt="">
				</div>
				
				<div class="bottom-pay">
					<div class="pay-btn" @click="pay">{{ order.final_payment_money > 0 ? '去支付' : '确定' }}</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import BtnType from "./btn-type.vue"

	export default {
		props: ['info'],
		components: {
			BtnType
		},
		data() {
			return {
				order: this.info,
				invoicedOrder: {},
				invoice_type_id: 1,
				price: 198.4,
				typeList: [{
					value: 1,
					name: '不开发票',
					state: 1
				}, {
					value: 2,
					name: '电子发票',
					state: 0
				}],
			}
		},
		created() {

		},
		mounted() {
			this.getInvoicedOrderInfo()
		},
		methods: {
			handleClick(val) {
				this.invoice_type_id = val.value
				this.order = val.value === 1 ? this.info : this.invoicedOrder
				this.typeList.forEach((item) => {
					if (item.name === val.name) {
						this.$set(item, 'state', 1)
					} else {
						this.$set(item, 'state', 0)
					}
				})
			},
			getInvoicedOrderInfo() {
				let params = {
					order_no: this.info.order_no,
					invoice_type_id: 2,
				}
				this.$api.order.getOrderDetail(params).then(res => {
					if (res.ret === 0) {
						this.invoicedOrder = res.data
					}
				}).catch(error => {

				})
			},
			pay() {
				let item = {
					value: 6,
					invoice_type_id: this.invoice_type_id
				}
				this.$emit('sureClick', 3, item)
			},
			evaluate() {
				// 19去小程序评价页面
				let item = {
					value: 19,
					invoice_type_id: this.invoice_type_id
				}
				this.$emit('sureClick', 3, item)
			}
		}
	}
</script>

<style scoped lang="scss">
	.order-pay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;

		.pay-info {
			background: white;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			padding: 20px;
			height: 40%;
			.pay-img {
				width: 50px;
				height: 50px;
				margin-bottom: 5px;
				margin-top: 20px;
			}
			.order-complete{
				color: #000;
				padding: 0 10px;
				margin-top: 10px;
				text-align: center;
				font-size: 18px;
			}
			.evaluate{
				margin: 50px auto;
				.evaluatesp {
					display: inline-block;
					font-size: 16px;
					line-height: 15px;
					color: #EE690D;
					margin-right: 5px;
				}
				.evaluate-img {
					height: 14px;
					height: 14px;
					vertical-align: middle;
				}
			}
			.bottom-pay {
				background: white;
				display: flex;
				padding: 10px;
				align-items: center;
			
				.pay-btn {
					width: 80%;
					height: 48px;
					line-height: 48px;
					background: #EE690D;
					border-radius: 8px;
					color: white;
					margin: 0 auto;
					font-weight: 500;
					font-size: 16px;
				}
			}
		}
		
		
	}
</style>
