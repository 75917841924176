<template>
	
	<div class="order-pay">
		<!-- <div class="alter-order-bottom" style="padding: 0px;background: transparent">
			<div class="pay-info">
				<img class="pay-img" src="../../assets/dui.png" alt="">
				<div class="order-complete"> 订单完成 </div>
				<div class="evaluate">
					<div class="evaluatesp" @click="evaluate">立即评价</div>
					<img class="evaluate-img" src="../../assets/pingjia.png" alt="">
				</div>
				
				<div class="bottom-pay">
					<div class="pay-btn" @click="pay">{{ order.final_payment_money > 0 ? '去支付' : '确定' }}</div>
				</div>
			</div>
		</div> -->
		
		<div class="alter-order-bottom" style="padding: 0px;background: transparent">
		  <div class="pay-info">
		    <div style="padding: 0 20px;">
		      <p class="info-title">{{ order.device_model_name }} {{ order.device_license_plate }} {{
		          order.driver_name
		        }}</p>
		      <div class="info-location">
		        <div class="location-tag">
		          <div class="tag-top"></div>
		          <div class="tag-line"></div>
		          <div class="tag-bottom"></div>
		        </div>
		        <div class="location-groups">
		          <div class="groups groups-start">{{ order.point_name }}</div>
		          <div class="groups groups-end">{{ order.uninstall_name }}</div>
		        </div>
		      </div>
		
		      <div class="order-info-des">
		        <p class="des-name">平台服务费</p>
		        <p class="des-price">{{ order.prepaid_pay_money ? order.prepaid_pay_money.toFixed(2) : '0.00' }}元</p>
		      </div>
		      <!-- <div class="order-info-distance">
		        <p class="distance distance-name">含起步里程{{ order.start_mileage }}公里</p>
		      </div> -->
		
		      <div class="order-info-des" v-if="order.device_model_id == 7">
		        <p class="des-name">里程费({{ order.total_mileage }}公里)</p>
		        <p class="des-price">{{ order.exceed_mileage_money ? order.exceed_mileage_money.toFixed(2) : '0.00' }}元</p>
		      </div>
		      <div class="order-info-distance" v-if="order.device_model_id == 7" >
		        <p class="distance distance-name">起步里程{{ order.start_mileage }}公里)</p>
				<p class="distance distance-price">
				  {{ order.start_price ? order.start_price.toFixed(2) : '0.00' }}元</p>
		      </div>
		      <div class="order-info-distance" v-if="order.device_model_id == 7">
		        <p class="distance distance-name">超出里程({{ order.exceed_mileage }}公里)</p>
		        <p class="distance distance-price">
		          {{ order.exceed_mileage_money ? order.exceed_mileage_money.toFixed(2) : '0.00' }}元</p>
		      </div>
		<!-- 
		      <div class="order-info-des">
		        <p class="des-name">逾时等候费({{ order.total_time }}分钟)</p>
		        <p class="des-price">{{ order.time_out_money ? order.time_out_money.toFixed(2) : "0.00" }}元</p>
		      </div>
		      <div class="order-info-distance">
		        <p class="distance distance-name">起步时长({{ order.max_wait_time }}分钟)</p>
		      </div>
		      <div class="order-info-distance" style="paddingBottom: 16px;">
		        <p class="distance distance-name">超出时长({{ order.time_out_time }}分钟)</p>
		        <p class="distance distance-price">{{
		            order.time_out_money ? order.time_out_money.toFixed(2) : '0.00'
		          }}元</p>
		      </div> -->
		
		    </div>
		   <!-- <div class="info-ticker" style="margin: 0;">
		      <p>发票</p>
		      <div class="ticker-btn-groups">
		        <div v-for="(item, index) in typeList" :key="index">
		          <BtnType :item="item" @clickItem="handleClick(item)"></BtnType>
		        </div>
		      </div>
		    </div>
		    <div class="info-ticker" style="marginTop: 0;borderTop: none;">
		      <p>优惠券</p>
		      <div class="ticker-btn-groups">
		        <p class="groups-money" v-if="order.coupon_money">-{{ order.coupon_money }}</p>
		        <p v-else class="no-money">暂无优惠券</p>
		      </div>
		    </div> -->
		    <div class="info-result-price">
		      <!-- <p class="result-price">折后价<span>{{
		          order.price_after_discount ? order.price_after_discount.toFixed(2) : 0
		        }}</span>元</p> -->
			  <p class="result-price">总金额<span>{{
			      order.original_money ? order.original_money.toFixed(2) : 0
			    }}</span>元</p>
		      <!-- <p class="init-price">原价<span>{{ order.original_money ? order.original_money.toFixed(2) : 0 }}</span>元</p> -->
		    </div>
		  </div>
		  <div class="bottom-pay">
		    <div class="pay-price">
				<p class="price">需支付<span>{{
			      order.original_money ? order.original_money.toFixed(2) : 0
			    }}</span>元</p>
		    <!--  <p class="price">
		        仅需支付<span>{{ order.final_payment_money.toFixed(2) }}</span>元</p>
		      <p class="preview-price">已支付服务费{{ order.prepaid_pay_money }}元</p> -->
		    </div>
		    <div class="pay-btn" @click="pay">完成订单</div>
		  </div>
		</div>
		
	</div>
</template>

<script>
	import BtnType from "./btn-type.vue"

	export default {
		props: ['info'],
		components: {
			BtnType
		},
		data() {
			return {
				order: this.info,
				invoicedOrder: {},
				invoice_type_id: 1,
				price: 198.4,
				typeList: [{
					value: 1,
					name: '不开发票',
					state: 1
				}, {
					value: 2,
					name: '电子发票',
					state: 0
				}],
			}
		},
		created() {

		},
		mounted() {
			this.getInvoicedOrderInfo()
		},
		methods: {
			handleClick(val) {
				this.invoice_type_id = val.value
				this.order = val.value === 1 ? this.info : this.invoicedOrder
				this.typeList.forEach((item) => {
					if (item.name === val.name) {
						this.$set(item, 'state', 1)
					} else {
						this.$set(item, 'state', 0)
					}
				})
			},
			getInvoicedOrderInfo() {
				let params = {
					order_no: this.info.order_no,
					invoice_type_id: 2,
				}
				this.$api.order.getOrderDetail(params).then(res => {
					if (res.ret === 0) {
						this.invoicedOrder = res.data
					}
				}).catch(error => {

				})
			},
			pay() {
				let item = {
					value: 19,
					invoice_type_id: this.invoice_type_id
				}
				this.$emit('sureClick', 3, item)
			},
			evaluate() {
				// 19去小程序评价页面
				let item = {
					value: 19,
					invoice_type_id: this.invoice_type_id
				}
				this.$emit('sureClick', 3, item)
			}
		}
	}
</script>

<style scoped lang="scss">
	// .order-pay {
	// 	position: fixed;
	// 	top: 0;
	// 	left: 0;
	// 	right: 0;
	// 	bottom: 0;
	// 	z-index: 999;

	// 	.pay-info {
	// 		background: white;
	// 		border-top-left-radius: 8px;
	// 		border-top-right-radius: 8px;
	// 		padding: 20px;
	// 		height: 40%;
	// 		.pay-img {
	// 			width: 50px;
	// 			height: 50px;
	// 			margin-bottom: 5px;
	// 			margin-top: 20px;
	// 		}
	// 		.order-complete{
	// 			color: #000;
	// 			padding: 0 10px;
	// 			margin-top: 10px;
	// 			text-align: center;
	// 			font-size: 18px;
	// 		}
	// 		.evaluate{
	// 			margin: 50px auto;
	// 			.evaluatesp {
	// 				display: inline-block;
	// 				font-size: 16px;
	// 				line-height: 15px;
	// 				color: #EE690D;
	// 				margin-right: 5px;
	// 			}
	// 			.evaluate-img {
	// 				height: 14px;
	// 				height: 14px;
	// 				vertical-align: middle;
	// 			}
	// 		}
	// 		.bottom-pay {
	// 			background: white;
	// 			display: flex;
	// 			padding: 10px;
	// 			align-items: center;
			
	// 			.pay-btn {
	// 				width: 80%;
	// 				height: 48px;
	// 				line-height: 48px;
	// 				background: #EE690D;
	// 				border-radius: 8px;
	// 				color: white;
	// 				margin: 0 auto;
	// 				font-weight: 500;
	// 				font-size: 16px;
	// 			}
	// 		}
	// 	}
		
		
	// }
	.order-pay {
	  position: fixed;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	  z-index: 999;
	
	  .pay-info {
	    margin: 10px;
	    background: white;
	    border-radius: 8px;
	    padding: 20px 0;
	
	    .info-title {
	      height: 22px;
	      font-size: 16px;
	      font-weight: 500;
	      color: #333333;
	      line-height: 22px;
	      padding-bottom: 16px;
	    }
	
	    .info-location {
	      display: flex;
	      align-items: center;
	
	      .location-tag {
	        display: flex;
	        flex-direction: column;
	      }
	
	      .tag-top {
	        width: 8px;
	        height: 8px;
	        background: #FF9500;
	        border-radius: 4px;
	      }
	
	      .tag-line {
	        width: 1px;
	        height: 10px;
	        background: #D8D8D8;
	        margin: 4px 4px;
	        box-sizing: border-box;
	      }
	
	      .tag-bottom {
	        width: 8px;
	        height: 8px;
	        background: #41D7B7;
	        border-radius: 4px;
	      }
	    }
	
	    .location-groups {
	      display: flex;
	      flex-direction: column;
	      flex: 1;
	
	      .groups {
	        height: 18px;
	        font-size: 12px;
	        font-weight: 500;
	        color: #666666;
	        line-height: 18px;
	        text-align: left;
	        padding-left: 10px;
	        flex: 1;
	      }
	
	      .groups-end {
	        padding-top: 8px;
	      }
	    }
	
	    .info-ticker {
	      display: flex;
	      padding: 15px 15px;
	      margin: 10px 0;
	      box-sizing: border-box;
	      border-top: 1px solid #eee;
	      border-bottom: 1px solid #eee;
	      align-items: center;
	
	      p {
	        font-size: 12px;
	      }
	
	      .ticker-btn-groups {
	        flex: 1;
	        display: flex;
	        justify-content: flex-end;
	
	        .groups-money {
	          color: #EE690D;
	          padding-right: 10px;
	        }
	
	        .no-money {
	          color: #cccccc;
	          padding-right: 10px;
	        }
	      }
	    }
	  }
	
	  .info-result-price {
	    padding: 15px 0 0 0;
	    // border-top: 1px solid #eee;
	    margin-top: 10px;
	
	    .result-price {
	      display: flex;
	      justify-content: center;
	      font-size: 12px;
	      align-items: flex-end;
	
	      span {
	        font-size: 24px;
	        line-height: 20px;
	      }
	    }
	
	    .init-price {
	      padding-top: 5px;
	      font-size: 10px;
	      align-items: flex-end;
	      display: flex;
	      justify-content: center;
	      color: #999999;
	      padding: 0 2px;
	
	      span {
	        font-size: 16px;
	        line-height: 16px;
	        text-decoration: line-through;
	        padding: 0 2px;
	      }
	    }
	
	  }
	
	  .bottom-pay {
	    background: white;
	    display: flex;
	    padding: 10px;
	    align-items: center;
	
	    .pay-price {
	      flex: 1;
	
	      .price {
	        display: flex;
	        align-items: flex-end;
	        font-size: 12px;
	
	        span {
	          font-size: 18px;
	          line-height: 18px;
	        }
	      }
	
	      .preview-price {
	        height: 14px;
	        font-size: 10px;
	        font-weight: 500;
	        color: #EE690D;
	        line-height: 14px;
	        padding-top: 4px;
	      }
	    }
	
	    .pay-btn {
	      width: 120px;
	      height: 48px;
	      line-height: 48px;
	      background: #EE690D;
	      border-radius: 8px;
	      color: white;
	      font-weight: 500;
	      font-size: 16px;
	    }
	  }
	}
	
</style>
