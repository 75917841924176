<template>
  <div class="map-float-content">

    <div class="content-bottom" @click="$emit('sureClick', 1)"  v-if="isUp">
		<!-- 订单信息 -->
      <OrderInfoPrice :price='info.estimated_money'></OrderInfoPrice>
    </div>
    <div v-else>
      <OrderDetail :detail="info" @anotherOrder="addOrder" @orderDetail="$emit('sureClick', 5)"></OrderDetail>
    </div>
    <div class="content-top top-driver">
      <OrderStateDes title="师傅正在赶来" :info="info">
        <slot>
          <span>师傅正在前往<span class="span-orange">{{ info.uninstall_name }}</span>途中，请在修车点等候</span>
          <span>预计<span class="span-orange">{{info.duration}}</span>分钟到达</span>
          <!-- <span>师傅正在前往<span class="span-orange">修车点</span>途中，请在修车点等候</span> -->
        </slot>
      </OrderStateDes>
	  <!-- 师傅详细信息 -->
      <DriverInfo :detail="info"></DriverInfo>
	  <!-- 取消订单 打电话 联系客服  发信息 -->
      <OrderActionItem :list="actionList"  :unReadNum="messageNumber" :detail="info" @clickItem="handleItem"></OrderActionItem>
    </div>
	<!-- 点击展开 -->
    <OrderOutIn :isUp="isUp" @clickArrow="handleArrow"></OrderOutIn>
  </div>
</template>

<script>
import {ORDER_ACTION_LIST1} from "../../model/stateModel.js"
import OrderInfoPrice from './excavatoruser/orderInfo/order-info-price.vue'
import OrderActionItem from './excavatoruser/orderInfo/order-action-item.vue'
import DriverInfo from './excavatoruser/orderInfo/driver-info.vue'
import OrderStateDes from './excavatoruser/orderInfo/order-state-des.vue'
import OrderOutIn from './excavatoruser/orderInfo/order-out-in.vue'
import OrderDetail from './excavatoruser/orderInfo/order-detail.vue'
export default {
  props: ['info','messageNumber'],
  components: {OrderInfoPrice, OrderActionItem, DriverInfo, OrderStateDes, OrderOutIn, OrderDetail},
  data() {
    return {
      isUp: true,
      actionList: ORDER_ACTION_LIST1,
    }
  },
  created() {
	console.log(info,"infoinfoinfoinfoinfo");
  },
  mounted() {

  },
  methods: {
    handleArrow() {
      this.isUp = !this.isUp
	  console.log(info,"infoinfoinfoinfoinfo");
    },
	// 用户可以点击 取消订单 打电话 联系客服  发信息
    handleItem(val) {
      this.$emit('sureClick', 3, val)
    },
    addOrder(){
      this.$emit('sureClick', 3, {value:7})
    }
  }
}
</script>

<style scoped lang="scss">
.top-driver {
  padding: 15px 0;
}

</style>
