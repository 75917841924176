<template>
  <div class="home" :style="{marginTop: paddingTopValue + 'px'}">
    <div class="map-contanier" id="container">
      <div style="display:none">
        <div id="myIcon" class="circleBox">
          <div class="circle1"></div>
          <div class="circle2"></div>
          <div class="circle3"></div>
          <div class="circle4"></div>
          <div class="circle5">
            <img class="circle-img" src="https://webapi.amap.com/ui/1.0/assets/position-picker2.png">
            <!-- <img class="circle-img" src="@/assets/map-xuanzhi.png"> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <img class="btn-close" :src="iconUrl" @click="handleClose"> -->
    <component
        ref="componentRef"
        :is="currentComponent"
        :info="componentInfo"
        @callBack="handleCallBack"
        @sureClick="handleSureClick"
        @actionClick="handleAction">
    </component>
  </div>
</template>

<script>
import {STATE_MODE, EVENT_MODEL, DRIVER_STATE_ORDER_MODE} from "../model/stateModel"
import DepartCar from "../components/excavator/excavatordriver/driver/depart-car.vue"// 出车 or 休息
// import GoingShipment from "../components/excavator/excavatordriver/driver/going-shipment.vue"// 去目的地

// import ArriveShipment from "../components/excavator/excavatordriver/driver/arrive-shipment.vue"// 已到达装货地点，请等待客户装货

// 去往修车点
import GoingUploading from "../components/excavator/excavatordriver/driver/going-uploading.vue"
// 到达修车点
import ArriveUploading from "../components/excavator/excavatordriver/driver/arrive-uploading.vue"
import UploadPic from "../components/excavator/excavatordriver/driver/upload-pic.vue"
import store from '@/store'
import basejs from '@/common/js/base.js'
import {isAndroid} from "@/utils/tools.js"

export default {
  name: "Home",
  components: {DepartCar , GoingUploading, ArriveUploading,UploadPic},
  data() {
    return {
      fromObj: {
        from: '',
        currentState: 0,
        unReadNum: false,
      },
      currentLocation: {},
      eventState: '',
      map: null,
      mapState: '',
      messageObj: {},
      currentComponent: '',
      componentInfo: {},
      orderDetailInfo: {},
      lineArr: [],
      totalLineArr: [],
      currentPosition: [],
      angle: '',
      imgBaseUrl: "http://res.hoopoeiot.cn/Sz_jgy_map/static/img/",
      mapTimeout: '',
      orderTimeout: '',
      mapTime: 5000,
      unread_num: 0,
      paddingTopValue: 0,
      isOnce: false,
      polyline: {},
      carMarker: {},
	  device_model_id:""//用来区分上门还是用户去门店 7上门服务 8去门店
    }
  },
  created() {
    const u = navigator.userAgent;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS) {
      this.paddingTopValue = 0
    } else {
      this.paddingTopValue = 28
    }
  },
  mounted() {
    document.title = "订单详情"
    this.init()
  },
  // 页面销毁
  destroyed() {
    this.map.destroy();
  },
  methods: {
    async init() {
      this.fromObj = this.$route.query
      this.unread_num = this.fromObj.unreadNum ? parseInt(this.fromObj.unreadNum) : 0
	  this.fromObj.v = this.$route.query['v'] || 20220610
      this.tickets = this.fromObj.tickets ? this.fromObj.tickets : '10834d020e675d34115cfddb0885a1264d1d5f0209eeaa7cc9ff7447fd1e6171e96a7f86bf567bbac59e47c35d8c29875f59384e0af669517c2f9b8e7ff50a1cb0a3c7a8a1395b163f2aa81619ecacffbf68a3b4b6abd69f21b47931096ae412cdbe69404f3cba81af7ef0e71dec703aab5711c9359a2bac67fc89e2f946b39b7d585a68f2a08633'
      this.orderSn = this.fromObj.orderSn ? this.fromObj.orderSn : 'DS20211213143646515384'
	  this.device_model_id = this.fromObj.device_model_id ? this.fromObj.device_model_id : ''
	  console.log( this.fromObj," this.fromObj this.fromObj"); 
	  // console.log( this.fromObj," this.fromObj this.fromObj");
	  
	  if(this.fromObj.location && this.fromObj.location !== "undefined" ){
		// console.log("11111111");
		this.currentLocation =  JSON.parse(this.fromObj.location)
		
	  }else{
		  // console.log("11111111222222222222");
		  this.currentLocation = {
			'longitude': 120.610868,
			'latitude': 31.329679
		  }
	  }
      // this.currentLocation = this.fromObj.location ? JSON.parse(this.fromObj.location) : {
      //   'longitude': 120.610868,
      //   'latitude': 31.329679
      // }
      //console.log('location====', this.currentLocation)
	  store.state.$v = this.fromObj.v
      store.state.$tickets = this.tickets
      if (!this.fromObj.hasOwnProperty('from')) {
		  
        this.mapState = STATE_MODE[this.fromObj.currentState]
		console.log(this.mapState,"false init()");
      }
      //初始化地图
      this.initMap()
      //获取行程数量
      await this.getDriverOrderNum()
      if (this.fromObj.from == 'order') {
		console.log(this.fromObj,'获取行程数量');
        await this.getOrderDetail()
        // if (this.fromObj.currentState === 12 || this.fromObj.currentState === 14) {
        //   await this.getOrderLocus()
        // }
        // if (this.fromObj.currentState === 13 || this.fromObj.currentState === 15) {
        //   await this.getRealPolyLines()
        //   this.startRealTime()
        // }
        await this.getOrderLocus()
        // if (this.fromObj.currentState === 13 || this.fromObj.currentState === 15) {
        //   await this.getRealPolyLines()
        //   this.timeoutRealTime()
        // }
        await this.getRealPolyLines()
        this.timeoutRealTime()
        this.timeOutGetOrderDetail()
		
        this.mapState = STATE_MODE[this.fromObj.currentState]
		
        this.orderDetailInfo.mapState = this.mapState
      }
      this.judgementState(this.fromObj.currentState)
    },
    handleDestroy() {
      clearInterval(this.mapTimeout)
      clearInterval(this.orderTimeout)
      this.map.destroy();
    },
    // 状态判断
    judgementState(val) {
		console.log("师傅状态判断",val);
      switch (parseInt(val)) {
        case 10:
          // 出车
          this.componentInfo = {
            messageObj: this.messageObj,
            mapState: this.mapState
          }
          this.currentComponent = 'DepartCar'
          break;
        case 11:
          // 休息
          this.componentInfo = {
            messageObj: this.messageObj,
            mapState: this.mapState
          }
          this.currentComponent = 'DepartCar'
          break;
        case 12:
          this.componentInfo = {
            order: this.orderDetailInfo,
            messageObj: this.messageObj,
            mapState: this.mapState
          }
          // this.currentComponent = 'GoingShipment'
		  // 师傅前往修车点
          this.currentComponent = 'GoingUploading'
		  if(this.device_model_id == 7){
			   this.currentComponent = 'GoingUploading'
		  }else if(this.device_model_id == 8){
			  this.currentComponent = 'ArriveUploading'
		  }
          break;
        case 13:
          this.componentInfo = {
            order: this.orderDetailInfo,
            messageObj: this.messageObj,
            mapState: this.mapState
          }
          this.currentComponent = 'ArriveShipment'
          break;
        case 14:
          this.componentInfo = {
            order: this.orderDetailInfo,
            messageObj: this.messageObj,
            mapState: this.mapState
          }
          this.currentComponent = 'ArriveUploading'
          break;
        case 15:
          this.componentInfo = {
            order: this.orderDetailInfo,
            messageObj: this.messageObj,
            mapState: this.mapState
          }
          this.currentComponent = 'ArriveUploading'
          break;
		// case 16:
		// //发起收款
		// 	this.componentInfo = {
		// 	  order: this.orderDetailInfo,
		// 	  messageObj: this.messageObj,
		// 	  mapState: this.mapState
		// 	}
		// 	this.currentComponent = 'ArriveUploading'
		// break;
      }
    },
	judgementMiniProgram() {
	  if (!window.WeixinJSBridge || !WeixinJSBridge.invoke) {
	    return document.addEventListener('WeixinJSBridgeReady', this.ready, false)
	  } else {
	    return this.ready()
	  }
	},
	ready() {
	  return window.__wxjs_environment === 'miniprogram'
	},

    // 关闭
    handleClose() {
      console.log('close....')
      this.myUni.navigateBack({delete: 1})
    },
    // 反向传值 - uniapp
    handleClick(item) {
		console.log('反向传值');
      this.myUni.postMessage({
        data: {
          action: "message",
          state: this.eventState,
          obj: {}
        },
      });
	  console.log('judgementMiniProgram', this.judgementMiniProgram())
	  if (this.judgementMiniProgram()) {
	    this.myUni.navigateBack({delete: 99, flagPush: true})
	  }
    },


    // 数据回传
    handleCallBack(val) {

      // this.fromObj.lng =[val.location.lng,val.location.lat]
      //this.loadPositionPicker(this.positionPicker)
      // this.initMap()
      // this.componentInfo.nearestPOI = val.name
      // if (val.location) {
      //   this.map.setCenter([val.location.lng,val.location.lat])
      //   this.componentInfo = val
      // } else {
      //   this.$message({
      //     content:'请选择具体地址',
      //     confirmText:'确定',
      //     confirmColor:'#ff0',
      //     showCancel:false,
      //   }).then((action)=>{
      //     //确定
      //   }).catch((action)=>{
      //     //取消
      //   });
      // }
    },
    handleSureClick(state = null, item) {
		//8 15
      console.log(state, item,"handleSureClick监听子组件")
      let index = basejs.findKey(DRIVER_STATE_ORDER_MODE, state)
	  console.log(index, state, item);
	  if(state==15){
		  let params = {
		    order_no: this.orderSn,
		  }
		  this.myUni.postMessage({
		    data: {
		      action: "message",
		      params: params,
		      state: "UPLOAD_PIC_ARRIVE",
		     
		    },
		  });
		  console.log('judgementMiniProgram', this.judgementMiniProgram())
		  if (this.judgementMiniProgram()) {
		    this.myUni.navigateBack({delete: 99, flagPush: true})
		  }
		  return;
	  }
	  // if(state==15){
		 //  this.componentInfo = {
		 //    order: this.orderDetailInfo,
		 //    messageObj: this.messageObj,
		 //    mapState: this.mapState
		 //  }
		 //  this.currentComponent = 'UploadPic'
		 //  return ;
	  // }
	  
      this.updateOrderStatus(index, state, item)
      // this.dealSuccess(state,item)
	  // console.log(this.updateOrderStatus,"indexindexindex");
    },
    // 子组件点击事件
    handleAction(state, item) {
      this.eventState = state
	  console.log(state,item,"子组件点击事件state, item");
      switch (state) {
        case 'PERSON_CENTER':
          // 个人中心
          console.log('个人中心')
          this.handleClick()
          break;
        case 'ITINERARY_MESSAGE':
          // 行程中心
          console.log('行程中心')
          this.handleClick()
          break;
        case 'BEGIN_GUIDE':
          // 导航
          this.handleClick()
          //this.jumpGuide()
          break;
        case 11:
          // 休息

          // this.handleClick()
          this.driverRest();
          break;
        case 4:
          // 打电话
          console.log('打电话')
          this.handleClick()
          break;
        case 5:
          //console.log(11111)
          // 发信息
          this.orderDetailInfo.num_unread = 0
          this.handleClick()
          break;
      }
    },
    // 导航事件
    jumpGuide() {
      console.log('isAndroid', isAndroid())
      let {address, latitude, longitude} = {}
      if ([5, 6].includes(this.orderDetailInfo.order_status)) {
        address = this.orderDetailInfo.point_name
        latitude = this.orderDetailInfo.point_latitude
        longitude = this.orderDetailInfo.point_longitude
      } else {
        address = this.orderDetailInfo.uninstall_name
        latitude = this.orderDetailInfo.uninstall_latitude
        longitude = this.orderDetailInfo.uninstall_longitude
      }

      try {
        let domain;
        let _this = this
        if (isAndroid()) {
          // android
          //window.webkit.messageHandlers.navTo.postMessage({order_no: this.ordersn})
          domain = "androidamap:"
        } else {
          // ios
          //window.client.navTo({order_no: this.ordersn})
          domain = "iosamap:"
        }
        window.location.href = `${domain}//viewMap?sourceApplication=uni.jgydriver&poiname=${address}&lat=${latitude}&lon=${longitude}&dev=0` //打开某手机上的某个app应用
        setTimeout(function () {
          alert(`${domain}//viewMap?sourceApplication=uni.jgydriver&poiname=${address}&lat=${latitude}&lon=${longitude}&dev=0`)
          _this.$message({
            content: '请先安装高德地图',
            confirmText: '确定',
            confirmColor: '#ff0',
            showCancel: false,
          })
        }, 500);

      } catch (e) {
        console.log('error:', e)
      }

    },
    //司机休息
    driverRest() {
      this.$message({
        content: '确认不接单了吗',
        confirmText: '确定',
        confirmColor: '#ff0',
        showCancel: true,
      }).then(res => {
        this.$api.flat.startRest().then(res => {
          if (res.ret === 0) {
            this.handleClick()
          } else {
            this.$message({
              content: res.msg,
              confirmText: '确定',
              confirmColor: '#ff0',
              showCancel: false,
            })
          }
        })
      }).catch(error => {

      })
    },
    updateOrderStatus(status, state, item) {
		// 8 15  
		console.log(status, state, item,"师傅滑动 更改订单状态");
      if (['RECEIVE_ORDER', 'GET_LOCATION'].includes(state)) {
        if (state == 'RECEIVE_ORDER') {
          this.$api.flat.startRest().then(res => {
            if (res.ret === 0) {
              this.dealSuccess(state, item)
            } else {
              this.$message({
                content: res.msg,
                confirmText: '确定',
                confirmColor: '#ff0',
                showCancel: false,
              })
            }
          })
        } else {
          this.$api.flat.startWork().then(res => {
            if (res.ret === 0) {
              this.dealSuccess(state, item)
            } else {
              this.$message({
                content: res.msg,
                confirmText: '确定',
                confirmColor: '#ff0',
                showCancel: false,
              })
            }
          })
        }
      } else {
		  // 如果是师傅已接单改为去目的的
		  if(status == 8){
			  let params = {
			    order_no: this.orderSn,
			    order_status: 8
			  }
		  }
		  // if(status == 8)
		  console.log(params ,"params");
        let params = {
          order_no: this.orderSn,
          order_status: parseInt(status) + 1
        }
		
        this.$api.order.driverUpdateOrderStatus(params).then(res => {
          if (res.ret == 0) {
            this.isOnce = false
            if (params.order_status === 9) {
              this.eventState = 'TO_PAY'
              this.handleClick()
            } else {
              this.dealSuccess(state, item)
            }
          } else {
            this.$toast(res.msg)
            this.$refs.componentRef.handleError()
          }
        }).catch(error => {
          console.log('...')
          this.$toast('服务器异常，请稍后再试!')
          this.$refs.componentRef.handleError()
        })
      }

    },
    async dealSuccess(state, item) {
      //this.map.clearMap()
      clearInterval(this.mapTimeout)
      if (!['GET_LOCATION', 'RECEIVE_ORDER'].includes(state)) {
        await this.getOrderDetail()
        await this.getOrderLocus()
		this.map.clearMap()
      }
	  console.log(state,"state组件展示")
      switch (state) {
		  // 组件展示
        case 'GET_LOCATION':   // 出车
          this.mapState = 'RECEIVE_ORDER'
          this.judgementState(11)
          break
        case 'RECEIVE_ORDER':    // 休息
          this.mapState = 'GET_LOCATION'
          this.judgementState(10)
          break
        case 12:
          // 前往装货点
          this.mapState = 'ARRIVE_SHIPMENT'
          this.fromObj.currentState = 13
          this.judgementState(13)
          //开启实时位置显示
          this.startRealPolyLines()
          break;
        case 13:
          this.mapState = 'GOING_UPLOADING'
          this.fromObj.currentState = 14
          //重绘地图
          this.getOrderLocus().then(res => {
            this.drawRoute()
          })
          this.judgementState(14)
          this.timeoutRealTime()
          break
        case 14:
          this.mapState = 'ARRIVE_UPLOADING'
          //开启实时位置显示
          this.fromObj.currentState = 15
          this.startRealPolyLines()
          this.judgementState(15)
		  this.getOrderLocus().then(res => {
		    this.drawRoute()
		  })
      }
    },
    // 初始化地图插件
    initMap() {
      // 初始化地图
      this.map = new AMap.Map('container', {
        center: this.fromObj.lng,
        // viewMode: '3D',
        zoom: 16,//级别
        scrollWheel: false,
        dragEnable: true,
        doubleClickZoom: true,
        touchZoom: true,
        resizeEnable: true,
        mapStyle: "amap://styles/macaron"
      });

      this.loadMapUI()
    },
    // 加载mapUI
    loadMapUI() {
      let that = this
      AMapUI.loadUI(['misc/PositionPicker', 'overlay/SimpleMarker', 'overlay/SimpleInfoWindow'], function (PositionPicker, SimpleMarker, SimpleInfoWindow) {
        switch (that.mapState) {
          case 'GET_LOCATION':
            that.addMark({'lng': that.currentLocation.longitude, 'lat': that.currentLocation.latitude})
            break;
          case 'RECEIVE_ORDER':
            that.addMark({'lng': that.currentLocation.longitude, 'lat': that.currentLocation.latitude})
            break;
        }
        // that.addCurrentLocation()
      })
    },
    // 设置窗体文本
    settingSimpleInfoWindow(SimpleInfoWindow, param) {
      let infoWindow = new SimpleInfoWindow({
        infoBody: param.content,
        offset: param.offset
      })
      infoWindow.open(this.map, this.map.getCenter())
    },
    // 设置图标
    loadSimpMarker(SimpleMarker, isShowRipple = false, position, imgName) {
      //  设置图标
      let iconStyle = {}
      if (isShowRipple) {
        iconStyle = document.getElementById('myIcon')
      } else {
        iconStyle = {
          src: this.imgBaseUrl + imgName,
          style: {
            width: '35px',
            height: '35px'
          },
        }
      }
      new SimpleMarker({
        iconStyle,
        map: this.map,
        position: position,
      })
    },
    // 加载positionPicker - 拖动选址
    loadPositionPicker(PositionPicker) {
      let that = this
      let positionPicker = new PositionPicker({
        mode: 'dragMap',
        map: that.map,
        iconStyle: { //自定义外观
          url: this.imgBaseUrl + 'dangqianweizhi1.png',
          ancher: [24, 40],
          size: [35, 35]
        }
      });

      positionPicker.on('success', function (positionResult) {
        that.getAddress(positionResult)

      })
      positionPicker.start(this.fromObj.lng);
      // positionPicker.stop();
    },
    // 异步同时加载多个插件
    loadPlugin() {
      let that = this
      switch (that.mapState) {
        case 'ARRIVE_UPLOADING':
        case 'ARRIVE_SHIPMENT':
          that.drawRealRoute()
          break;
        case 'GOING_SHIPMENT':
        case 'GOING_UPLOADING':
          that.drawRoute()
          break;
      }
    },
    async startRealPolyLines() {
      await this.getOrderLocus()
      await this.getRealPolyLines()
      this.timeoutRealTime()
    },
    //绘制实时路径
    drawRealRoute() {
      this.polyline = new AMap.Polyline({
        map: this.map,
        path: this.lineArr,
        showDir: true,
        strokeColor: "#56B266",  //线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6,      //线宽
        // strokeStyle: "solid"  //线样式
      });
      this.carMarker = new AMap.Marker({
        map: this.map,
        position: this.currentPosition,
        icon: new AMap.Icon({
          image: "https://jx.hoopoeiot.cn/res/hoepaas/logo/truck_green2_small.png",
          size: new AMap.Size(24, 48),  //图标所处区域大小
          imageSize: new AMap.Size(24, 48) //图标大小
        }),
        offset: new AMap.Pixel(-13, -26),
        autoRotation: true,
        angle: this.angle,
      });


      let endMarker = new AMap.Marker({
        map: this.map,
        position: this.lineArr[this.lineArr.length - 1],
        // icon: this.imgBaseUrl + 'zhong.png',
        offset: new AMap.Pixel(-24, -48),
        autoRotation: true,
      });

      let qiMarker = new AMap.Marker({
        map: this.map,
        position: this.lineArr[0],
        // icon: this.imgBaseUrl + 'qi.png',
        offset: new AMap.Pixel(-24, -48),
        autoRotation: true,
      });

      // this.settingMarket(endMarker, this.orderDetailInfo.uninstall_name, 'zhong.png')
      // this.settingMarket(qiMarker, this.orderDetailInfo.point_name, 'qi.png')
	  this.settingMarket(qiMarker, this.orderDetailInfo.point_name, 'qi.png')
	  this.settingMarket(endMarker, this.orderDetailInfo.uninstall_name, 'zhong.png')

      // this.loadSimpMarker(this.initSimpleMarker, false, this.lineArr[this.lineArr.length - 1], 'zhong.png')

      // 调整视野达到最佳显示区域
      if (!this.isOnce) {
        this.map.setFitView()
      }
      this.isOnce = true
      // }
    },
    // 设置终点、起点
    settingMarket(market, content, imgName) {
      let markerContent = document.createElement("div")
      let markerSpan = document.createElement("span")
      markerSpan.className = 'adaptive-width marker'
      markerSpan.innerHTML = content
      markerContent.appendChild(markerSpan)

      let markerImg = document.createElement("img")
      markerImg.className = "markerlnglat"
      markerImg.src = this.imgBaseUrl + imgName
      markerContent.appendChild(markerImg)


      market.setContent(markerContent)
    },
    //绘制路径
    drawRoute(route = '') {
      let marker = new AMap.Marker({
        map: this.map,
        position: this.lineArr[0],
        icon: this.imgBaseUrl + 'qi.png',
        offset: new AMap.Pixel(-24, -48),
        autoRotation: true,
      });
      let endMarker = new AMap.Marker({
        map: this.map,
        position: this.lineArr[this.lineArr.length - 1],
        icon: this.imgBaseUrl + 'zhong.png',
        offset: new AMap.Pixel(-24, -48),
        autoRotation: true,
      });
      let polyline = new AMap.Polyline({
        map: this.map,
        path: this.lineArr,
        showDir: true,
        strokeColor: "#56B266 ",  //线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6,      //线宽
        // strokeStyle: "solid"  //线样式
      });
      if ([5, 6, 7].includes(this.orderDetailInfo.order_status)) {
        this.settingMarket(endMarker, this.orderDetailInfo.uninstall_name, 'zhong.png')
		this.settingMarket(marker, this.orderDetailInfo.point_name, 'qi.png')
      } else {
        this.settingMarket(endMarker, this.orderDetailInfo.uninstall_name, 'zhong.png')
        this.settingMarket(marker, this.orderDetailInfo.point_name, 'qi.png')
      }


      this.map.setFitView()

    },

    //解析地图路径
    parseRouteToPath(route) {
      let path = []

      for (let i = 0, l = route.steps.length; i < l; i++) {
        let step = route.steps[i]

        for (let j = 0, n = step.path.length; j < n; j++) {
          path.push(step.path[j])
        }
      }

      return path
    },
    // 标点 - mark
    addMark(item) {
      let marker = new AMap.Marker({
        icon: this.imgBaseUrl + 'dangqianweizhi1.png',
        position: new AMap.LngLat(item.lng, item.lat)
      })
      this.map.add(marker)
      this.addFitView()
    },
    // 当前位置
    addCurrentLocation() {
      let that = this
      // 设置图标大小
      let settingIcon = new AMap.Icon({
        image: that.imgBaseUrl + 'dangqianweizhi.png',
        size: new AMap.Size(35, 35),
        imageSize: new AMap.Size(35, 35),
        imageOffset: new AMap.Pixel(0, 0)
      })
      console.log('=======', that.currentLocation)
      this.delayTimer = setTimeout(() => {
        let marker = new AMap.Marker({
          icon: settingIcon,
          position: new AMap.LngLat(that.currentLocation.longitude, that.currentLocation.latitude)
        })
        that.map.add(marker)
        that.addFitView()
      }, 0);
    },
    // 自动适配到合适的视野范围
    addFitView() {
      this.map.setFitView();
    },
    //获取订单信息
    getOrderDetail() {
      return new Promise((resolve, reject) => {
        this.$api.order.getOrderDetail({'order_no': this.orderSn}).then(res => {
			// console.log(res.data," this.orderDetailInfo获取订单信息")
          if (res.ret === 0) {
            this.orderDetailInfo = res.data
            this.fromObj.currentState = DRIVER_STATE_ORDER_MODE[this.orderDetailInfo.order_status] ? DRIVER_STATE_ORDER_MODE[this.orderDetailInfo.order_status] : 0
            resolve()
          }
        }).catch(error => {
          console.log(error)
        })

      })
    },
    timeoutRealTime() {
      if (this.mapTimeout) {
        clearInterval(this.mapTimeout)
      }
      this.mapTimeout = setInterval(() => {
        this.getRealPolyLines()
      }, this.mapTime);
    },
    timeOutGetOrderDetail() {
      if (this.orderTimeout) {
        clearInterval(this.orderTimeout)
      }
      this.orderTimeout = setInterval(() => {
        this.getRealOrder()
      }, 10000);
    },
    getRealOrder() {
      this.$api.order.getOrderDetail({'order_no': this.orderSn}).then(res => {
        if (res.ret === 0) {
          let order = res.data
		  // console.log(order,"getRealOrder");
          if (order.order_status == 1) {
            this.handleAction('ITINERARY_MESSAGE')
            return
          }
          if (order.uninstall_longitude != this.orderDetailInfo.uninstall_longitude || order.uninstall_latitude != this.orderDetailInfo.uninstall_latitude) {
            this.handleDestroy()
            this.init()
          }
        }
      }).catch(error => {

      })
    },
    getRealPolyLines() {
      return new Promise((resolve, reject) => {
        this.$api.order.getCarLoc({'order_no': this.orderSn}).then(res => {
          if (this.fromObj.currentState == 13 || this.fromObj.currentState == 15 ||this.fromObj.currentState == 14) {
            // this.map.clearMap();
            this.angle = res.data.direction
            //res.data.index = Math.floor(Math.random()*(1 - 200) + 200);
            this.lineArr = this.totalLineArr.filter((item, index) => index >= res.data.index)
            this.currentPosition = this.lineArr[0]
            this.orderDetailInfo.total_money = res.data.total_money
            if (this.fromObj.currentState == 13 || this.fromObj.currentState == 15) {
              this.polyline.setPath(this.lineArr)
              const position = [res.data.longitude, res.data.latitude]
              this.carMarker.setPosition(position)
            }
          }
          this.orderDetailInfo.num_unread = res.data.num_unread
          resolve()
        }).catch(error => {

        })
      })
    },
    getOrderLocus() {
      return new Promise((resolve, reject) => {
        if (Object.keys(this.carMarker).length > 0) {
          resolve();
          return;
        }
		console.log(this.orderDetailInfo,"orderDetailInfoorderDetailInfoorderDetailInfo");
		let parms ={}
		parms.point_longitude = this.orderDetailInfo.point_longitude
		parms.point_latitude = this.orderDetailInfo.point_latitude
		parms.uninstall_longitude = this.orderDetailInfo.uninstall_longitude
		parms.uninstall_latitude = this.orderDetailInfo.uninstall_latitude
		// doOrdinaryCar getOrderPolyline {'order_no': this.orderSn}
        this.$api.order.doOrdinaryCar(parms).then(res => {
          this.orderDetailInfo.total_point_time = res.data.duration
          this.orderDetailInfo.total_point_distance = res.data.distance
          this.totalLineArr = this.lineArr = res.data.polylines.map(item => {
            return [item.longitude, item.latitude]
          })
          this.currentPosition = this.lineArr[0]
          if ([12, 14].includes(this.fromObj.currentState)) {
            this.drawRoute()
          } else {
            this.drawRealRoute();
          }
          resolve()
        }).catch(error => {

        })
      })
    },
	// 我的行程展示
    getDriverOrderNum() {
      return new Promise((resolve, reject) => {
        this.$api.order.myItineraryShow().then(res => {
          if (res.ret === 0) {
            this.messageObj.message = this.unread_num
            this.messageObj.total_count = res.data.total_count
			// console.log(this.messageObj.message,this.messageObj.total_count,"this.messageObj.message,this.messageObj.total_count")
            resolve()
          }
        }).catch(error => {

        })

      })

    }
  },
};
</script>
<style lang="scss">

@import '@/style/map.scss';

.marker {
  position: absolute;
  top: -30px;
  right: -40px;
  width: 120px;
  color: #000;
  padding: 4px 5px;
  box-shadow: 1px 1px 1px rgba(10, 10, 10, .2);
  white-space: nowrap;
  font-size: 12px;
  font-family: "";
  background-color: #FFF;
  border-radius: 3px;
}

.adaptive-width {
  width: auto;
}

</style>
