<template>
  <div class="driver-info">
    <div class="info">
      <p class="info-car-type">{{ detail.driver_name }}</p>
      <!-- <p class="info-car-weight">{{ detail.device_model_name }} • 载重{{detail.min_load}}～{{detail.max_load}}吨</p> -->
      <p class="info-name-orders">{{ detail.driver_total_order_count }}单 </p> 
	  <p class="info-tyre-praise">好评率100%</p>
    </div>
    <img :src="detail.driver_avatar" alt="">
  </div>
</template>

<script>
export default {
  props: ['detail'],
  data() {
    return {
      price: 10,
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.driver-info {
  border-top: 1px solid #EEEEEE;
  display: flex;
  padding: 10px 20px;
  align-items: flex-start;
  .info {
    flex: 1;
    .info-car-type {
      height: 33px;
      line-height: 33px;
      font-size: 24px;
    }
    .info-car-weight {
      padding-top: 5px;
      color: #666666;
      height: 20px;
      line-height: 20px;
    }
    .info-name-orders {
      padding-top: 4px;
      height: 20px;
      line-height: 20px;
      color: #999999;
	  display: inline-block;
	  float: left;
	  margin-right: 20px;
    }
	.info-tyre-praise{
		font-size: 14px;
		padding-top: 4px;
		height: 20px;
		line-height: 20px;
		color:#F7921D;	  
		float: left;
		display: inline-block;
	}
  }
  img {
    width: 48px;
    height: 48px;
  }
}

</style>
