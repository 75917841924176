<template>
  <div class="select-address-content">
    <SearchPlace ref="searchPlace" v-if="isShowSearch" @clickCancel="handleCancel" @clickSure="clickSure" :info="addressInfo"></SearchPlace>
    <div class="content-footer">
      <div class="name-address">
        <p class="name">{{info['address']}}</p>
        <p class="address-btn" @click="changeAddress">更改地址</p>
      </div>
      <p class="address">{{info['fullAddress']}}</p>
      <div class="sure-btn" @click="handleSure">
        确认
      </div>
    </div>

  </div>
</template>

<script>
import SearchPlace from  "./tyremenduser/orderInfo/search-place.vue"
export default {
  props: ['info', 'isShow'],
  components: {SearchPlace},
  data() {
    return {
      addressInfo: '',
      isShowSearch: this.isShow,
    }
  },
  watch: {
    info: {
      handler(newV) {
        console.log('===newV:', JSON.stringify(newV))
        this.addressInfo = newV
        // this.$emit(this.addressInfo, 'currentAddress', newV.name)
        // this.$emit
      },
      immediate: true,
      deep: true
    }
  },
  created() {

  },
  mounted() {
    // this.initPlaceSearch()
  },
  methods: {
    clickSure(item) {
      // this.$set(this.addressInfo, 'currentAddress', item.currentAddress)
      // this.$set(this.addressInfo, 'fullAddress', item.fullAddress)
      // this.$set(this.addressInfo, 'location', item.location)

      // console.log('this.addressInfo ===', JSON.stringify(this.addressInfo))
      this.$emit('callBack', item, true)
      this.isShowSearch = false
    },
    handleCancel() {
      // this.isShowSearch = false
      this.$emit('sureClick', 99)
    },
    handleSure() {
      console.log('sure...')
      this.$emit('sureClick', 0, this.addressInfo)
    },
    changeAddress() {
      this.isShowSearch = true
      this.$nextTick(() => {
        this.$refs.searchPlace.getPlaceList(this.addressInfo.address)
      })
    },
  }
}
</script>

<style scoped lang="scss">
.name-address {
  display: flex;
  flex-direction: row;

}
.name {
  flex: 1;
  font-weight: 500;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.address-btn {
  width: 64px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #EEEEEE;
  font-size: 12px;
  text-align: center;
  line-height: 24px;
  color: #666666;
  font-weight: 500;
}
.address {
  padding: 5px 0 25px;
  color: #999999;
  line-height: 18px;
  width: 100%;
  font-size: 12px;
}
</style>
