<template>
  <div class="depart-car driver-container">
    <div class="driver-top" :style="{top: paddingTopValue + 'px'}">
      <Navigation :info="info.messageObj" @clickPerson="$emit('actionClick', eventModel.PERSON_CENTER)" @clickItinerary="$emit('actionClick', eventModel.ITINERARY_MESSAGE)"></Navigation>
    </div>
    <div class="driver-bottom">
      <div :class="info.mapState === 'GET_LOCATION' ? 'bottom-depart' : 'bottom-xiuxi'" class="bottom-btn" @click="handleAction">
        <p>{{info.mapState === 'GET_LOCATION' ? '接单' : '休息'}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {EVENT_MODEL} from "../../../../model/stateModel"
import Navigation from "./navigation/index.vue"
export default {
  props: ['info'],
  components: {Navigation},
  data() {
    return {
      eventModel: EVENT_MODEL,
      paddingTopValue: 0
    }
  },
  created() {
    const u = navigator.userAgent;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS) {
      this.paddingTopValue = 0
    } else {
      this.paddingTopValue = 28
    }
  },
  mounted() {

  },
  methods: {
    handleAction() {
      this.$message({
        content: `确认${this.info.mapState === 'GET_LOCATION' ? '接单' : '休息'}吗？`,
        confirmText: '确定',
        confirmColor: '#ff0',
        showCancel: true,
      }).then(res => {
        console.log('mapState=============', this.info.mapState)
        this.$emit('sureClick', this.info.mapState)
      }).catch(error => {

      })
      
    }
  }
}
</script>

<style scoped lang="scss">
.depart-car {
  .driver-bottom {
    display: flex;
    justify-content: center;
    padding: 16px 0;
    .bottom-btn {
      
      height: 50px;
      border-radius: 25px;
      line-height: 50px;
      width: 60%;
      p {
        color: white;
        text-align: center;
        font-size: 20px;
      }
    }
    .bottom-depart {
      background: linear-gradient(90deg, #EE690D 0%, #F8A21E 100%);
    }
    .bottom-xiuxi {
      background: linear-gradient(270deg, #4C5574 0%, #3D415A 100%);
    }
  }
}
</style>
