import { render, staticRenderFns } from "./order-state-des.vue?vue&type=template&id=8d781936&scoped=true&"
import script from "./order-state-des.vue?vue&type=script&lang=js&"
export * from "./order-state-des.vue?vue&type=script&lang=js&"
import style0 from "./order-state-des.vue?vue&type=style&index=0&id=8d781936&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d781936",
  null
  
)

export default component.exports