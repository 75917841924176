<template>
  <div class="map-float-content">
    <div class="content-bottom" @click="$emit('sureClick', 1)"  v-if="isUp">
      <OrderInfoPrice :price='info.estimated_money'></OrderInfoPrice>
    </div>
    <div v-else>
       <OrderDetail :detail="info" @anotherOrder="addOrder" @orderDetail="$emit('sureClick', 5)"></OrderDetail>
    </div>
    <div class="content-top top-call">
      <div class="call-driver">
        <div class="driver">
          <p>正在呼叫附近师傅</p>
          <img src="@/assets/loading.gif" alt="">
        </div>
        <p class="des">请耐心等候</p>
      </div>
      <div class="call-btn" @click="$emit('sureClick', 2)">取消订单</div>
    </div>
    <OrderOutIn :isUp="isUp" @clickArrow="handleArrow"></OrderOutIn>
  </div>
</template>

<script>
import OrderInfoPrice from './excavatoruser/orderInfo/order-info-price.vue'
import OrderOutIn from './excavatoruser/orderInfo/order-out-in.vue'
import OrderDetail from './excavatoruser/orderInfo/order-detail.vue'
export default {
  props: ['info'],
  components: {OrderInfoPrice, OrderOutIn, OrderDetail},
  data() {
    return {
      isUp: true
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    handleArrow() {
      this.isUp = !this.isUp
    },
    addOrder(){
      this.$emit('sureClick', 3, {value:7})
    }
  }
}
</script>

<style scoped lang="scss">
.map-float-content {
  flex-direction: column-reverse;
}
.top-call {
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .call-driver {
    flex: 1;
    display: flex;
    flex-direction: column;
    .driver {
      display: flex;
      align-items: center;
      p {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        padding-right: 8px;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
    .des {
      color: #999999;
      line-height: 20px;
      font-size: 12px;
    }
  }
  .call-btn {
    border: 1px solid #DDDDDD;
    line-height: 24px;
    height: 24px;
    width: 64px;
    border-radius: 4px;
    color: #666666;
    font-size: 12px;
  }
}

</style>
