<template>
  <div class="travel">
    <div class="travel-phone">
      <img :src="info.user_avatar" alt="">
      <p>{{ info.user_telephone_privacy }}</p>
      <div class="phone" style="margin-right: 10px;position:relative;" @click="$emit('clickMessage')">
        <img src="@/assets/xinxi.png" alt="">
        <div class="message-count" v-if="info.num_unread>0">{{ info.num_unread }}</div>
      </div>

      <div class="phone" @click="$emit('clickPhone')">
        <img src="@/assets/dianhua.png" alt="">
      </div>
    </div>
    <div class="travel-location" >
      <p>正在前往<span>{{info.uninstall_name}}</span>途中</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data() {
    return {}
  },
  created() {

  },
  mounted() {

  },
  methods: {}
}
</script>

<style scoped lang="scss">
.travel {
  display: flex;
  flex-direction: column;
  width: 100%;

  .travel-phone {
    padding: 16px 20px;
    display: flex;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
    }

    p {
      flex: 1;
      font-size: 16px;
      padding-left: 8px;
    }

    .phone {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      border: 1px solid #eee;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .travel-location {
    border-top: 1px solid #eee;
    padding: 12px 20px;
    width: 100%;

    p {
      color: #999999;
      font-size: 14px;
    }

    span {
      color: #EE690D;
    }
  }
}
</style>
