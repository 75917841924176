<template>
  <div class="home">
    <div class="map-contanier" id="container">

    </div>
    <!-- <img class="btn-close" :src="iconUrl" @click="handleClose"> -->
    <img class="btn-close" src="@/assets/btn_close.png" @click="handleClose">
    <component
        :is="currentComponent"
        :info="componentInfo"
        :isShow="isShow"
        @callBack="handleCallBack"
        @sureClick="handleSureClick">
    </component>
  </div>
</template>

<script>
import {STATE_MODE, EVENT_MODEL, STATE_ORDER_MODE} from "../model/stateModel"
import SelectAddress from "@/components/select-address.vue"


import store from '@/store'

export default {
  name: "ChooseLocation",
  components: {
    SelectAddress
  },
  data() {
    return {
      fromObj: {
        lng: [],
        from: '',
        currentState: 0,
        startLng: [],
        endLng: [],
        currentLocation: [],
        currentLocationObj: {},
      },
      isInit: true,
      isSelect: false,
      selectPlaceItem: '',
      eventState: 0,
      map: null,
      mapState: '',
      iconUrl: '',
      positionPicker: null,
      dragPoint: [],
      currentComponent: '',
      componentInfo: '',
      tickets: '',
      currentPosition: [],
      isOnce: false,        // 只加载一次
      imgBaseUrl: "http://res.hoopoeiot.cn/Sz_jgy_map/static/img/",
      isShow: true,        // 是否显示place
    }
  },
  created() {
    document.title = "地图选址"
  },
  mounted() {
    this.loadInit()
  },
  // 页面销毁
  destroyed() {
    this.handleDestroy()
  },
  methods: {
    handleDestroy() {
      this.isOnce = false
      this.map.destroy();
    },
    loadInit() {
      this.fromObj = this.$route.query
      this.fromObj.currentState = 0
	  this.fromObj.v = this.$route.query['v'] || 20220610
	  this.fromObj.chooseType = this.$route.query['from']  
      this.fromObj.currentLocationObj = this.fromObj['location'] ? JSON.parse(decodeURIComponent(this.fromObj['location'])) : ''
      let selectLocation = this.fromObj['address'] ? JSON.parse(this.fromObj['address']) : ''
      if (selectLocation && Object.keys(selectLocation).length > 0) {
        this.isShow = false
      }
	  
	  this.tickets = this.fromObj.tickets ? this.fromObj.tickets : ''
	  store.state.$tickets = this.tickets
	  store.state.$v = this.fromObj.v
	  store.state.$chooseType = this.fromObj.chooseType
      this.init()
    },
    // 初始化
    async init(status = true) {

      this.iconUrl = require(this.mapState === 'SELECT_ADDRESS' ? '@/assets/btn_close.png' : '@/assets/btn_fanhui.png')
      this.mapState = STATE_MODE[this.fromObj.currentState]
      this.fromObj.lng = this.getLng()
      // this.eventState = this.fromObj.from === 'start' ? EVENT_MODEL.CONFIRM_ADDRESS_STARE : EVENT_MODEL.CONFIRM_ADDRESS_End
	  switch(this.fromObj.from){
		case "start":
			this.eventState = EVENT_MODEL.CONFIRM_ADDRESS_STARE //0
		break;
		case "end":
			this.eventState = EVENT_MODEL.CONFIRM_ADDRESS_End  //1
		break;
		case "tyre" :
			this.eventState = EVENT_MODEL.CONFIRM_ADDRESS_Tyre //2
		break;
	  }
	  console.log(this.fromObj ,this.eventState,"初始化this.eventState");
      this.initMap()
      this.judgementState()

    },
    // 状态判断
    judgementState() {
      switch (parseInt(this.fromObj.currentState)) {
        case 0:
          this.currentComponent = 'SelectAddress'
          break;
      }
    },
    //根据信息获取经纬度
    getLng() {
      let address = this.fromObj.address
      if (address) {
        return this.getChooseAddressLng(address)
      } else {
        return this.getDefaultLng()
      }
    },
    //获取已选地址的经纬度
    getChooseAddressLng(address) {
      try {
        return JSON.parse(decodeURIComponent(address))['position']
      } catch (e) {
        return this.getDefaultLng()
      }
    },
    //获取默认的经纬度
    getDefaultLng() {
      // 返回当前位置
      // return [this.fromObj.currentLocationObj['longitude'], this.fromObj.currentLocationObj['latitude']]
      return [120.610868, 31.329679];
    },
    // 关闭
    handleClose() {
      // console.log('close....')
      // this.myUni.navigateBack({delete: 99})
      this.eventState = EVENT_MODEL.TO_ORDER
      this.handleClick()
    },
    // 反向传值 - uniapp
    handleClick(obj = {}) {
      let params = {
        address: this.componentInfo.address,
        fullAddress: this.componentInfo.fullAddress,
        position: this.componentInfo.position
      }
      if (this.fromObj.from === 'changeEndPlace') {
        this.nextTo()
        return
      }
	  console.log(this.eventState,"this.eventStatethis.eventState");
      this.myUni.postMessage({
        data: {
          action: "message",
          address: this.eventState < 2 ? params : '',
          queryFrom: this.fromObj.from,
          state: this.eventState,
          obj: obj
        },
      });
      console.log('judgementMiniProgram', this.judgementMiniProgram())
      if (this.judgementMiniProgram()) {
        this.myUni.navigateBack({delete: 99})
      }
    },
    // 判断是否是小程序
    judgementMiniProgram() {
      if (!window.WeixinJSBridge || !WeixinJSBridge.invoke) {
        return document.addEventListener('WeixinJSBridgeReady', this.ready, false)
      } else {
        return this.ready()
      }
    },
    ready() {
      return window.__wxjs_environment === 'miniprogram'
    },

    nextTo() {
      if (this.eventState === 17) {
        this.$store.state.$alertEndPlace =false
        this.$router.go(-1)
        return
      }
      if (this.eventState === 1) {
        this.$store.state.$alertEndPlace =true
        this.$store.state.$alertItem ={
          address:this.componentInfo.address,
          fullAddress:this.componentInfo.fullAddress,
          location:{
            lng:this.componentInfo.position[0],
            lat:this.componentInfo.position[1],
          }
        };
        this.$router.go(-1)
        return
      }
    },
    getReturnAddress(val) {
      let {name, address} = val.regeocode.pois[0]
      return {
        fullAddress: address,
        address: name,
        position: [val.position.lng, val.position.lat],
      }

    },
    getAddress(val) {
      if (this.isSelect) {
        this.isSelect = false
        val = {
          regeocode: {
            pois: [
              {
                'name': this.selectPlaceItem.address,
                'address': this.selectPlaceItem.fullAddress,

              }
            ]
          },
          position: this.selectPlaceItem.location
        }
      }
      // this.componentInfo = val
      this.componentInfo = this.getReturnAddress(val)
      // this.componentInfo.currentAddress = obj.address
      // this.componentInfo.fullAddress = obj.fullAddress
      // console.log('this.componentInfo === ', JSON.stringify(this.componentInfo))
    },
    getParamsAddress() {
      let item = this.fromObj.currentLocationObj
      let selectLocation = this.fromObj['address'] ? JSON.parse(this.fromObj['address']) : ''
      this.componentInfo = {
        fullAddress: selectLocation.fullAddress,
        address: selectLocation.address,
        position: [item.longitude, item.latitude]
      }
    },
    // 数据回传
    handleCallBack(val, isSelect, item) {
      this.isSelect = isSelect
      this.selectPlaceItem = val
      let arr = Object.keys(val.location)
      if (arr.length > 0) {
        this.map.setCenter([val.location.lng, val.location.lat])
        val = {
          address: val.address,
          fullAddress: val.fullAddress,
          position: [
            val.location.lng,
            val.location.lat
          ]
        }
        this.componentInfo = val
      } else {
        this.$message({
          content: '请选择具体地址',
          confirmText: '确定',
          confirmColor: '#ff0',
          showCancel: false,
        }).then((action) => {
          //确定
        }).catch((action) => {
          //取消
        });
      }
    },
    // 选址确认
    handleSureClick(state = null, item) {
		console.log(state,item,"state,item选址确认");
      switch (state) {
        case 0:   //选址地址-确定
          this.handleClick(item)
          break
        case 99:
          this.handleClose()
          console.log('1212221')
          break
      }
    },
    // 初始化地图插件
    initMap() {
      // 初始化地图
      this.map = new AMap.Map('container', {
        center: this.fromObj.lng,
        // viewMode: '3D',
        zoom: 16,//级别
        scrollWheel: false,
        dragEnable: true,
        doubleClickZoom: true,
        touchZoom: true,
        resizeEnable: true,
        mapStyle: "amap://styles/macaron"
      });

      if (this.fromObj.currentState == 0) {
        this.map.on('zoomstart', this.mapZoomstart);
        this.map.on('zoomend', this.mapZoomend);
      }


      this.mapLayer = new AMap.LabelsLayer({
        zooms: [3, 20],
        zIndex: 1000,
        // collision: false,
        // 设置 allowCollision：true，可以让标注避让用户的标注
        allowCollision: false,
      });
      this.loadMapUI()
    },
    // 加载mapUI
    loadMapUI() {
      let that = this
      // console.log('mapState', that.mapState)
      AMapUI.loadUI(['misc/PositionPicker', 'overlay/SimpleMarker', 'overlay/SimpleInfoWindow'], function (PositionPicker, SimpleMarker, SimpleInfoWindow) {
        switch (that.mapState) {

          case 'SELECT_ADDRESS':          //选址
            // that.positionPicker = PositionPicker
            that.loadPositionPicker(PositionPicker)
            break;
          default:
            console.log('无订单')

        }
      })
    },

    // 加载positionPicker - 拖动选址
    loadPositionPicker(PositionPicker) {
      let iconUrl = this.imgBaseUrl + 'dangqianweizhi1.png'
      if (this.eventState === 0) {
        // 开始
        iconUrl = this.imgBaseUrl + 'zhuang2.png'
      } else if(this.eventState === 1) {
        // 结束
        iconUrl = this.imgBaseUrl + 'xie2.png'
      } else if(this.eventState === 2){
		  // 流动补胎
		   iconUrl = this.imgBaseUrl + 'bu.png'
		   // iconUrl = this.imgBaseUrl + 'dangqianweizhi1.png'
	  }
      let that = this
      this.positionPicker = new PositionPicker({
        mode: 'dragMap',
        map: that.map,
        iconStyle: { //自定义外观
          url: iconUrl,
          ancher: [24, 40],
          size: [35, 35]
        }
      });

      this.positionPicker.on('success', function (positionResult) {
        // console.log('positionResult=====', positionResult)
        let val = {}
        if (that.isInit !== true) {
          that.getAddress(positionResult)
        } else {
          that.getParamsAddress()
        }
        that.isInit = false


      })
      this.startPositionPickerLocation()
    },
    // 开启定位
    startPositionPickerLocation() {
      if (this.fromObj.lng) {
        this.positionPicker.start(this.fromObj.lng);
      } else {
        if (this.fromObj.currentLocationObj['longitude'] && this.fromObj.currentLocationObj['latitude']) {
          this.positionPicker.start([this.fromObj.currentLocationObj['longitude'], this.fromObj.currentLocationObj['latitude']])
        } else {
          this.positionPicker.start(this.fromObj.lng);
        }
      }
    },
    // 开始缩放
    mapZoomstart() {
      console.log('drag end.....')
      // this.positionPicker.stop();
      // that.positionPicker.start(that.componentInfo.position)
    },
    // 结束缩放
    mapZoomend() {
      let that = this
      // setTimeout(() => {
      //   console.log('drag start.....')
      that.positionPicker.start(that.componentInfo.position)
      // }, 0);
    },
    //解析地图路径
    parseRouteToPath(route) {
      let path = []

      for (let i = 0, l = route.steps.length; i < l; i++) {
        let step = route.steps[i]

        for (let j = 0, n = step.path.length; j < n; j++) {
          path.push(step.path[j])
        }
      }

      return path
    },


    // 自动适配到合适的视野范围
    addFitView() {
      this.map.setFitView();
    },
  },
};
</script>
<style lang="scss">

@import '@/style/map.scss';

</style>
