const STATE_MODE = {
    0: 'SELECT_ADDRESS',                  // 选择地址
    1: 'CALL_DRIVER',                     // 呼叫司机
    2: 'DRIVER_GET_ORDER',                // 司机已接单
    3: 'DRIVER_GOING',                    // 司机正在赶来
    4: 'DRIVER_ARRIVE',                   // 司机已到达
    5: 'DRIVER_GO_DESTINATION',           // 司机正在前往目的地
    6: 'DRIVER_ARRIVE_DESTINATION',       // 司机到达目的地
    7: 'ORDER_PAY',                       // 支付
    8: 'FINISH_ORDER',                   // 完成订单

    10: 'GET_LOCATION',                   // 获取当前位置   -   出车
    11: 'RECEIVE_ORDER',                  // 接单中        -   休息
    12: 'GOING_SHIPMENT',                 // 前往装货点
    13: 'ARRIVE_SHIPMENT',                // 到达装货点
    14: 'GOING_UPLOADING',                // 前往卸货点
    15: 'ARRIVE_UPLOADING',               // 到达卸货点


    //16: 'FINISH_ORDER',                   // 完成订单

    3000: 'HAVE_ORDER',
}


const ORDER_ACTION_LIST1 = [
    {name: '取消订单', value: 1, icon: require('@/assets/quxiao.png')},
    {name: '打电话', value: 2, icon: require('@/assets/dianhua.png')},
    {name: '发消息', value: 3, icon: require('@/assets/xinxi.png')},
    {name: '联系客服', value: 4, icon: require('@/assets/kefu.png')},
]

const ORDER_ACTION_LIST2 = [
    {name: '修改终点', value: 5, icon: require('@/assets/xiugai.png')},
    {name: '打电话', value: 2, icon: require('@/assets/dianhua.png')},
    {name: '发消息', value: 3, icon: require('@/assets/xinxi.png')},
    {name: '联系客服', value: 4, icon: require('@/assets/kefu.png')},
]

const ORDER_ACTION_LIST3 = [
    {name: '打电话', value: 2, icon: require('@/assets/dianhua.png')},
    {name: '发消息', value: 3, icon: require('@/assets/xinxi.png')},
    {name: '联系客服', value: 4, icon: require('@/assets/kefu.png')},
]

const EVENT_MODEL = {
    CONFIRM_ADDRESS_STARE: 0,           // 确认使用开始地址
    CONFIRM_ADDRESS_End: 1,             // 确认使用结束地址
	CONFIRM_ADDRESS_Tyre:2,  			// 确认使用流动补胎地址
    CANCEL_ORDER: 2,                    // 取消订单
    GET_ORDER_INFO: 3,                  // 获取订单信息
    CALL_PHONE: 4,                      // 打电话
    SEND_MESSAGE: 5,                    // 发信息
    CONTACT_KEFU: 6,                    // 联系客服
    ALTER_END_PLACE: 7,                 // 修改终点
    PAY_ORDER: 8,                       // 去支付
    ANOTHER_ORDER: 9,                   // 再来一单
    TO_ORDER:17,                        // 去订单列表
    TO_ORDER_DETAIL:18,                        // 去订单详情
	USER_ONE_KEY_NAVIGATION:20,          //用户一键导航去店铺
	TO_EVALUATE:19,						//去评价
	PHONE:21,  							//电话客服
	
	
	

    DEPART_CAR: 10,                    // 出发
    XIUXI_CAR: 11,                      //休息
    GONG_ZHUANGHUODIAN: 12,             // 前往装货点
    ARRIVE_ZHUANGHUODIAN: 13,           // 到达装货点
    GONG_XIEHUODIAN: 14,                // 前往 卸货点
    ARRIVE_XIEHUODIAN: 15,              // 到达卸货点
    CAR_PAY: 16,                        // 发起收款

    PERSON_CENTER: 'PERSON_CENTER',                  //个人中心
    ITINERARY_MESSAGE: 'ITINERARY_MESSAGE',              // 我的行程
    BEGIN_GUIDE: 'BEGIN_GUIDE',                    // 开始导航
}

const STATE_ORDER_MODE = {
    1: 0,									// 已取消
    2: 8,									// 已完成
    3: 7,									// 待付款
    4: 1,                  				// 已付款，呼叫司机
    5: 2,                    				// 司机已接单
    6: 3,                					// 司机正在赶来
    7: 4,                    				// 司机已到达
    8: 5,                   				// 司机正在前往目的地
    9: 6,           						// 司机到达目的

    3000: 'HAVE_ORDER',
}

const DRIVER_STATE_ORDER_MODE = {
    1: 0,									// 已取消
    2: 0,									// 已完成
    3: 7,									// 待付款
    4: 1,                  				    // 已付款，呼叫司机
    5: 12,                    				// 司机已接单
    6: 13,                					// 司机正在赶来
    7: 14,                    				// 司机已到达
    8: 15,                   				// 司机正在前往目的地
    9: 16,           						// 司机到达目的

    3000: 'HAVE_ORDER',
}


export {
    STATE_MODE,
    ORDER_ACTION_LIST1,
    ORDER_ACTION_LIST2,
	ORDER_ACTION_LIST3,
    EVENT_MODEL,
    STATE_ORDER_MODE,
    DRIVER_STATE_ORDER_MODE
}