<template>
  <div class="order-pay">
    <div class="alter-order-background"></div>
    <div class="alter-order-bottom" style="padding: 0px;background: transparent">
      <div class="pay-info">
        <div style="padding: 0 20px;">
          <p class="info-title">
			  <span class="shi">
				  施工费
			  </span>
			  <span class="money">
				   {{ order.total_pay_money }}元
			  </span>
			
			</p>
          <div class="info-location">
				{{order.time_out_time_str}}
          </div>
		</div>
		   <div class="info-ticker" style="margin: 0;">
			 <!-- <p>发票</p>
			  <div class="ticker-btn-groups">
				<div v-for="(item, index) in typeList" :key="index">
				  <BtnType :item="item" @clickItem="handleClick(item)"></BtnType>
				</div>
			  </div>
			</div>
			<div class="info-ticker" style="marginTop: 0;borderTop: none;">
			  <p>优惠券</p>
			  <div class="ticker-btn-groups">
				<p class="groups-money" v-if="order.coupon_money">-{{ order.coupon_money }}</p>
				<p v-else class="no-money">暂无优惠券</p>
			  </div> -->
			</div>
        <div class="info-result-price">
          <p class="result-price">{{
             order.total_pay_money ? order.total_pay_money.toFixed(2) : 0
            }}元</p>
          <!-- <p class="init-price">原价<span>{{ order.original_money ? order.original_money.toFixed(2) : 0 }}</span>元</p> -->
        </div>
      </div>
      <div class="bottom-pay">
        <div class="pay-price" @click="handleItem">
			<div class="price">
				<span>如有疑问</span>
				<span>联系客服</span>
			</div>
			<img class="img" src="../../assets/phone.png" alt="">
        </div>
        <div class="pay-btn" @click="pay">{{ order.total_pay_money > 0 ? '去支付' : '完成订单' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
	import BtnType from "./btn-type.vue"

	export default {
		props: ['info'],
		components: {
			BtnType
		},
		data() {
			return {
				order: this.info,
				invoicedOrder: {},
				invoice_type_id: 1,
				price: 198.4,
				typeList: [{
					value: 1,
					name: '不开发票',
					state: 1
				}, {
					value: 2,
					name: '电子发票',
					state: 0
				}],
			}
		},
		created() {

		},
		mounted() {
			console.log(this.order,"order")
			this.getInvoicedOrderInfo()
		},
		methods: {
			handleClick(val) {
				this.invoice_type_id = val.value
				this.order = val.value === 1 ? this.info : this.invoicedOrder
				this.typeList.forEach((item) => {
					if (item.name === val.name) {
						this.$set(item, 'state', 1)
					} else {
						this.$set(item, 'state', 0)
					}
				})
			},
			getInvoicedOrderInfo() {
				let params = {
					order_no: this.info.order_no,
					invoice_type_id: 2,
				}
				this.$api.order.getOrderDetail(params).then(res => {
					if (res.ret === 0) {
						this.invoicedOrder = res.data
					}
				}).catch(error => {

				})
			},
			pay() {
				// let item = {
				// 	value: 19,
				// 	invoice_type_id: this.invoice_type_id
				// }
				// this.$emit('sureClick', 3, item)
				let item = {
				  value: 6,
				  invoice_type_id: this.invoice_type_id
				}
				this.$emit('sureClick', 3, item)
			},
			evaluate() {
				// 19去小程序评价页面
				let item = {
					value: 19,
					invoice_type_id: this.invoice_type_id
				}
				this.$emit('sureClick', 3, item)
			},
			handleItem(val) {
				let item = {
					value:21,
					invoice_type_id: this.invoice_type_id
				}
			  this.$emit('sureClick', 3, item)
			}
		}
	}
</script>

<style scoped lang="scss">
	// .order-pay {
	// 	position: fixed;
	// 	top: 0;
	// 	left: 0;
	// 	right: 0;
	// 	bottom: 0;
	// 	z-index: 999;

	// 	.pay-info {
	// 		background: white;
	// 		border-top-left-radius: 8px;
	// 		border-top-right-radius: 8px;
	// 		padding: 20px;
	// 		height: 40%;
	// 		.pay-img {
	// 			width: 50px;
	// 			height: 50px;
	// 			margin-bottom: 5px;
	// 			margin-top: 20px;
	// 		}
	// 		.order-complete{
	// 			color: #000;
	// 			padding: 0 10px;
	// 			margin-top: 10px;
	// 			text-align: center;
	// 			font-size: 18px;
	// 		}
	// 		.evaluate{
	// 			margin: 50px auto;
	// 			.evaluatesp {
	// 				display: inline-block;
	// 				font-size: 16px;
	// 				line-height: 15px;
	// 				color: #EE690D;
	// 				margin-right: 5px;
	// 			}
	// 			.evaluate-img {
	// 				height: 14px;
	// 				height: 14px;
	// 				vertical-align: middle;
	// 			}
	// 		}
	// 		.bottom-pay {
	// 			background: white;
	// 			display: flex;
	// 			padding: 10px;
	// 			align-items: center;
			
	// 			.pay-btn {
	// 				width: 80%;
	// 				height: 48px;
	// 				line-height: 48px;
	// 				background: #EE690D;
	// 				border-radius: 8px;
	// 				color: white;
	// 				margin: 0 auto;
	// 				font-weight: 500;
	// 				font-size: 16px;
	// 			}
	// 		}
	// 	}
		
		
	// }
	.order-pay {
	  position: fixed;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	  z-index: 999;
	
	  .pay-info {
	    margin: 10px;
	    background: white;
	    border-radius: 8px;
	    padding: 20px 0;
	
	    .info-title {
			display: flex;
			justify-content: space-between;
	      height: 22px;
	      color: #333333;
	      line-height: 22px;
	      padding-bottom: 10px;
		  .shi{
			  font-size: 16px;
			  font-weight: 600;
		  }
		  .money{
			  font-size: 12px;
			  font-weight: 500;
			  color: #333333;
		  }
		  
	    }
	
	    .info-location {
			text-align: left;
			font-size: 12px;
			color: #999999;
	    }
	
	    .location-groups {
	      display: flex;
	      flex-direction: column;
	      flex: 1;
	
	      .groups {
	        height: 18px;
	        font-size: 12px;
	        font-weight: 500;
	        color: #666666;
	        line-height: 18px;
	        text-align: left;
	        padding-left: 10px;
	        flex: 1;
	      }
	
	      .groups-end {
	        padding-top: 8px;
	      }
	    }
	
	    .info-ticker {
	      display: flex;
	      padding: 15px 15px;
	      margin: 10px 0;
	      box-sizing: border-box;
	      // border-top: 1px solid #eee;
	      border-bottom: 1px solid #eee;
	      align-items: center;
	
	      p {
	        font-size: 12px;
	      }
	
	      .ticker-btn-groups {
	        flex: 1;
	        display: flex;
	        justify-content: flex-end;
	
	        .groups-money {
	          color: #EE690D;
	          padding-right: 10px;
	        }
	
	        .no-money {
	          color: #cccccc;
	          padding-right: 10px;
	        }
	      }
	    }
	  }
	
	  .info-result-price {
	    padding: 15px 0 0 0;
	    // border-top: 1px solid #eee;
	    margin-top: 10px;
	
	    .result-price {
	      display: flex;
	      justify-content: center;
	      font-size: 24px;
	      align-items: flex-end;
		  font-weight: 600;
	
	      span {
	        font-size: 24px;
	        line-height: 20px;
	      }
	    }
	
	    .init-price {
	      padding-top: 5px;
	      font-size: 10px;
	      align-items: flex-end;
	      display: flex;
	      justify-content: center;
	      color: #999999;
	      padding: 0 2px;
	
	      span {
	        font-size: 16px;
	        line-height: 16px;
	        text-decoration: line-through;
	        padding: 0 2px;
	      }
	    }
	
	  }
	
	  .bottom-pay {
	    background: white;
	    display: flex;
	    padding: 10px;
	    align-items: center;
		justify-content: space-between;
	    .pay-price {
			display: flex;
			padding: 0 30px ;
			box-sizing: border-box;
			justify-content: space-between;
			width: 169px;
			height: 48px;
			line-height: 48px;
			border-radius: 8px ;
			color: white;
			font-weight: 500;
			font-size: 16px;
			background-color: #00B40C;
	      .price {
			height: 20px;
			font-size: 16px;
			font-weight: 500;
			line-height: 22px;
			color: #fff;
			margin-top: 4px;
	      }
	
	      .img {
	        width: 32px;
			height: 32px;
	        line-height: 32px;
			margin-top: 8px;
	      }
	    }
	
	    .pay-btn {
	      width: 169px;
	      height: 48px;
	      line-height: 48px;
	      background: #EE690D;
	      border-radius: 8px;
	      color: white;
	      font-weight: 500;
	      font-size: 16px;
	    }
	  }
	}
	
</style>
