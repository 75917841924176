<template>
  <div class="order-info-price">
    <p class="info-name">订单信息</p>
    <p class="info-price">进行中</p>
  </div>
</template>

<script>
export default {
  props: ['price'],
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.order-info-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  .info-name {
    font-size: 16px;
  }
  .info-price {
    flex: 1;
    padding: 0 10px;
    text-align: right;
    font-size: 12px;
  }
  img {
    width: 12px;
    height: 12px;
  }
}

</style>
