<template>
  <div class="driver-container going-shipment">
    <div class="driver-top" :style="{top: paddingTopValue + 'px'}">
      <Navigation title="前往代班点" :info="info.messageObj" @clickItinerary="$emit('actionClick', eventModel.ITINERARY_MESSAGE)"></Navigation>
    </div>
    <!-- <div class="driver-des" :style="{top: 44 + paddingTopValue + 'px'}">
      <p>已到达装货地点，请等待客户装货</p>
    </div> -->
    <div class="driver-guide" :style="{top: 98 + paddingTopValue + 'px'}">
      <!-- <Guide :info="order" @clickGuide="$emit('actionClick', eventModel.BEGIN_GUIDE)"></Guide> -->
    </div>
    <div class="driver-location" style="bottom: 74px;">
      <Location :info="order" @clickMessage="$emit('actionClick', eventModel.SEND_MESSAGE)" @clickPhone="$emit('actionClick', eventModel.CALL_PHONE)"></Location>
    </div>
    <div class="driver-bottom">
      <div class="bottom-xiuxi" @click="$emit('actionClick', eventModel.XIUXI_CAR)">休息</div>
      <div class="slid" @click="handleFinish">
        <SliderSwipe :state="state" @slidFinish="handleFinish" ref="slidSwipe">
          <!-- <p class="slot-name">前往装货点</p> -->
          <p class="slot-name">前往代班点</p>
        </SliderSwipe>
      </div>
    </div>
  </div>
</template>

<script>
import {EVENT_MODEL} from "../../../../model/stateModel"
import SliderSwipe from "../../excavatoruser/slider/slider-swipe.vue"
import Navigation from "./navigation2/index.vue"
import Location from "./orderInfo/location.vue"
import Guide from "./orderInfo/guide.vue"
export default {
  props: ['info'],
  components: {SliderSwipe, Navigation, Location, Guide},
  data() {
    return {
      eventModel: EVENT_MODEL,
      order:this.info.order,
      paddingTopValue: 0
    }
  },
  created() {
    const u = navigator.userAgent;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS) {
      this.paddingTopValue = 0
    } else {
      this.paddingTopValue = 28
    }
  },
  mounted() {

  },
  computed: {
    state() {
      return (this.info && this.info.mapState) ? this.info.mapState : ''
    }
  },
  methods: {
    handleFinish() {
      this.$emit('sureClick', 14)
    },
    handleError() {
      this.$refs.slidSwipe.init()
    }
  }
}
</script>

<style scoped lang="scss">
.going-shipment {
	.driver-location{
		margin-bottom: 15px;
	}
  .driver-bottom {
	  padding: 8px;
    display: flex;
    box-sizing: border-box;
  }
  .bottom-xiuxi {
    width: 100px;
    height: 64px;
    line-height: 64px;
    margin-right: 10px;
    box-sizing: border-box;
    background: linear-gradient(270deg, #4C5574 0%, #3D415A 100%);
    color: white;
    border-radius: 8px;
  }
  
  .slid {
    width: calc(100% - 110px);
  }
  .driver-des {
    position: fixed;
    top: 44px;
    left: 0px;
    right: 0px;
    background: white;
    height: 44px;
    border-top: 1px solid #eee;
    line-height: 44px;
    p {
      padding: 0 16px;
      color: #666666;
      font-size: 14px;
    }
  }
}
</style>
