<template>
  <div class="map-float-content">
    <div class="content-bottom" @click="$emit('sureClick', 1)" v-if="isUp">
      <OrderInfoPrice :price='info.estimated_money'></OrderInfoPrice>
    </div>
    <div v-else>
      <OrderDetail :detail="info" @anotherOrder="addOrder" @orderDetail="$emit('sureClick', 5)"></OrderDetail>
    </div>
    <div class="content-top top-driver">
      <OrderStateDes title="师傅已到达">
        <slot>
          <span>师傅已到目的地，请您尽快联系师傅换胎</span>
        </slot>
      </OrderStateDes>
      <DriverInfo :detail="info"></DriverInfo>
      <OrderActionItem :list="actionList" :unReadNum="messageNumber"  @clickItem="handleItem"></OrderActionItem>
    </div>
    <OrderOutIn :isUp="isUp" @clickArrow="handleArrow"></OrderOutIn>

    <AlterOrderEndLocation v-if="isShowAlter" :detail="info" :location="selectLocation" @clickCancel="endLocationCancel"
                           @clickSure="endLocationSure"></AlterOrderEndLocation>
    <div class="place-search">
      <SearchPlace v-if="isShowSearch" @clickCancel="handleCancel" @clickSure="clickSure"></SearchPlace>
    </div>

  </div>
</template>

<script>	
import {ORDER_ACTION_LIST2} from "../../model/stateModel.js"
import OrderInfoPrice from './tyremenduser/orderInfo/order-info-price.vue'
import OrderActionItem from './tyremenduser/orderInfo/order-action-item.vue'
import DriverInfo from './tyremenduser/orderInfo/driver-info.vue'
import OrderStateDes from './tyremenduser/orderInfo/order-state-des.vue'
import OrderOutIn from './tyremenduser/orderInfo/order-out-in.vue'
import OrderDetail from './tyremenduser/orderInfo/order-detail.vue'
import AlterOrderEndLocation from "./tyremenduser/orderInfo/alter-order-end-location";
import SearchPlace from "./tyremenduser/orderInfo/search-place";

export default {
  props: ['info','messageNumber'],
  components: {
    OrderInfoPrice,
    OrderActionItem,
    DriverInfo,
    OrderStateDes,
    OrderOutIn,
    OrderDetail,
    SearchPlace,
    AlterOrderEndLocation
  },
  data() {
    return {
      actionList: ORDER_ACTION_LIST2,
      isShowSearch: false,
      isShowAlter: false,
      isUp: true,
    }
  },
  created() {

  },
  mounted() {
    this.isShowAlter =this.$store.state.$alertEndPlace
    if(this.isShowAlter){
      this.selectLocation =this.$store.state.$alertItem
    }
  },
  methods: {
    handleArrow() {
      this.isUp = !this.isUp
    },
    handleItem(val) {
      if (val.value === 5) {
        // 修改终点
        //this.isShowSearch = true
        const info = this.info
        const location = JSON.stringify({'longitude': info.uninstall_longitude, 'latitude': info.uninstall_latitude})
        const address = JSON.stringify({
          "address": info.uninstall_name,
          "position": [info.uninstall_longitude, info.uninstall_latitude],
          "fullAddress": info.uninstall_address
        })
        let params = {
          time: (new Date()).getTime(),
          location: encodeURIComponent(location),
          address:address,
          from: 'changeEndPlace',
          type: 'end'
        }
        this.$router.push({name: 'chooseLocation', query: params})
        return
      }
      this.$emit('sureClick', 3, val)
    },
    addOrder() {
      this.$emit('sureClick', 3, {value: 7})
    },
    handleCancel() {
      this.isShowSearch = false
    },
    clickSure(item) {
      this.isShowSearch = false
      console.log('select:', item)
      this.isShowAlter = true
      this.selectLocation = item
    },
    endLocationCancel() {
      this.isShowAlter = false
      console.log('关闭。。。。')
    },
    endLocationSure() {
      this.isShowAlter = false
      this.$emit('sureClick', 4, this.selectLocation)
      console.log('确认修改。。。')
    },
  }
}
</script>

<style scoped lang="scss">
.top-driver {
  padding: 15px 0;
}

</style>
