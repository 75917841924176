<template>
  <div class="location-container">
    <div class="location-person">
      <img :src="info.user_avatar" alt="">
      <p>{{ `${info.user_telephone_privacy}` }}</p>
    </div>
    <div class="location-line" v-if="info.device_model_id == 7">
      <div class="dot-orange"></div>
      <div class="line"></div>
      <div class="dot-gree"></div>
    </div>
    <div class="location-address">
		<p class="address-end" >{{ info.point_name }}</p>
		
      <p class="address-start">{{ info.uninstall_name }}</p>
      
    </div>
    <div class="location-action">
      <div class="action-phone" @click="$emit('clickMessage')">
        <img src="@/assets/xinxi.png" alt="">
        <div class="message-count" v-if="info.num_unread>0">{{ info.num_unread }}</div>
      </div>
      <div class="action-message" @click="$emit('clickPhone')">
        <img src="@/assets/dianhua.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data() {
    return {
      unread_num: this.info.unread_num
    }
  },
  computed: {
    imgUrl() {
      return require('@/assets/morentouxiang.png')
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {}
}
</script>

<style scoped lang="scss">
.location-container {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;

  .location-person {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 32px;
      object-fit: contain;
    }

    p {
      color: #666666;
      font-size: 12px;
      height: 18px;
      line-height: 18px;
    }
  }

  .location-line {
    padding: 0 10px 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .dot-orange {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background: #FF9500;
    }

    .line {
      margin: 6px 0;
      width: 1px;
      background: #D8D8D8;
      box-sizing: border-box;
      height: 14px;
      margin-left: 3px;
    }

    .dot-gree {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background: #41D7B7;
    }
  }

  .location-address {
	margin-left: 10px;
    flex: 1;
    width: calc(100% - 86px - 38px - 50px - 32px - 20px);

    .address-start {
      font-size: 14px;
      line-height: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
	  padding-top: 18px;
    }

    .address-end {
      font-size: 14px;
      line-height: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      
    }
  }

  .location-action {
    display: flex;

    .action-phone {
      width: 32px;
      height: 32px;
      border: 1px solid #EEEEEE;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      position: relative;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .action-message {
      width: 32px;
      height: 32px;
      border: 1px solid #EEEEEE;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      margin-left: 20px;
      box-sizing: border-box;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
