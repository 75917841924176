<template>
  <div class="map-float-content">
    <div class="content-bottom"  v-if="isUp">
		<!-- @click="$emit('sureClick', 1)" -->
      <OrderInfoPrice :price='info.estimated_money'></OrderInfoPrice>
    </div>
    <div v-else>
      <OrderDetail :detail="info" @anotherOrder="addOrder" @orderDetail="$emit('sureClick', 5)"></OrderDetail>
    </div>
    <div class="content-top top-driver">
      <OrderStateDes title="师傅已接单" :info="info.device_model_id"  @getLocation="userNavigation" >
        <slot>
          <span>请耐心等待师傅到工作地点</span>
        </slot>
      </OrderStateDes>
      <DriverInfo :detail="info"></DriverInfo>
      <OrderActionItem :list="actionList"  :unReadNum="messageNumber" @clickItem="handleItem"></OrderActionItem>
    </div>
    <OrderOutIn :isUp="isUp" @clickArrow="handleArrow"></OrderOutIn>
  </div>
</template>

<script>
import basejs from '@/common/js/base.js'
import {ORDER_ACTION_LIST1} from "../../model/stateModel.js"
import OrderInfoPrice from './excavatoruser/orderInfo/order-info-price.vue'
import OrderActionItem from './excavatoruser/orderInfo/order-action-item.vue'
import DriverInfo from './excavatoruser/orderInfo/driver-info.vue'
import OrderStateDes from './excavatoruser/orderInfo/order-state-des.vue'
import OrderDetail from './excavatoruser/orderInfo/order-detail.vue'
import OrderOutIn from './excavatoruser/orderInfo/order-out-in.vue'
import moment from "moment";

export default {
  props: ['info', 'messageNumber'],
  components: {OrderInfoPrice, OrderActionItem, DriverInfo, OrderStateDes, OrderDetail, OrderOutIn},
  data() {
    return {
      actionList: ORDER_ACTION_LIST1,
      isUp: true,
      arriveDate: '',
    }
  },
  created() {
    this.arriveDate = `${basejs.getTimeDesc(this.info.car_time)} ${moment(parseInt(this.info.car_time) * 1000).format('HH:mm')}`
  },
  mounted() {

  },
  methods: {
    handleArrow() {
      this.isUp = !this.isUp
    },
    handleItem(val) {
		console.log(val,"一级组件");
      this.$emit('sureClick', 3, val)
    },
	// 用户一键导航去店铺
	userNavigation(val){
		console.log(val,"一键导航");
		this.$emit('sureClick', 3, val)
	},
    addOrder() {
      this.$emit('sureClick', 3, {value: 7})
    },
	addOrder() {
	  this.$emit('sureClick', 3, {value: 7})
	}
  }
}
</script>

<style scoped lang="scss">
.top-driver {
  padding: 15px 0;
}

</style>
