<template>
  <div class="content-search">
    <div class="search" :style="{paddingTop: paddingTopValue + 'px'}">
      <input id="tipinput" v-model="keyWord" placeholder="请输入地址" class="search-input" type="textfield"
             @input="handleInput">
      <img class="clear-img" src="../../assets/btn_close.png" alt="" @click="handleClear">
      <p class="cnacelBtn" @click="$emit('clickCancel')">取消</p>
    </div>
    <div class="pol-list">
      <div class="pol-flag" @click="handleMap">
        <img src="@/assets/map-xuanzhi.png" alt="">
        <p>地图选址</p>
      </div>
      <div class="list">
        <div class="pol-row" v-for="(item, index) in locationList" :key="index" @click="handleRow(item)">
          <div class="row-address">
            <img src="@/assets/dizhi.png" alt="">
            <p class="address-name">{{ item.name }}<span>{{ item.cname }}</span> </p>
          </div>
          <p class="address-intro">
            {{ item.address && item['address'].length > 0 ? item['address'] : item['district'] }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
export default {
  props: ['info'],
  data() {
    return {
      placeSearch: '',
      locationList: [],
      keyWord: '',
      paddingTopValue: 8,
      isDelete: false,
    }
  },
  watch: {
    info: {
      handler(newV) {
        this.info = newV
        // this.keyWord = newV.address
        this.getPlaceList()
      },
      deep: true
    }
  },
  created() {
    const u = navigator.userAgent;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS) {
      this.paddingTopValue = 8
    } else {
      this.paddingTopValue = 28 + 20
    }
  },
  mounted() {
  },
  methods: {
    handleClear() {
      this.keyWord = ''
      // this.getPlaceList()
    },
    handleMap() {
      if (this.locationList.length > 0) {
        // 有数据，默认选中第一个
        let item = this.locationList[0]
        this.handleRow(item)
      } else {
        this.handleRow({
          name: '',
          address: '',
          location: {
            longitude: this.info.position.lng,
            latitude: this.info.position.lat,
          }
        })
      }
    },
    handleInput() {
      // this.initPlaceSearch()
      this.isDelete = true
      if (this.keyWord) {
        this.getPlaceList()
      }
      console.log('keyWord====', this.keyWord)
    },
    handleRow(item) {
      let newItem = {
        address: item.name,
        fullAddress: item.address,
        location: {
          lng: item.longitude,
          lat: item.latitude
        }
      }
      this.$emit('clickSure', newItem)
      console.log('select item', JSON.stringify(newItem))
      this.keyWord = null
      this.locationList = []
    },
    getPlaceList(address = '') {
      if (address) {
        this.keyWord = address
      }
      let params = {
        name: this.keyWord,
		type: store.state.$chooseType,
        longitude: this.info.position[0],
        latitude: this.info.position[1],
      }
      console.log('============================---------', JSON.stringify(this.info))
      console.log('params======', JSON.stringify(params))
      this.$api.order.getPlace(params).then(res => {
        console.log('res==', JSON.stringify(res))
        if (res.ret === 0) {
          this.locationList = res.data
          // if (!this.keyWord && !this.isDelete) {
          //   let firstItem = this.locationList[0]
          //   this.keyWord = firstItem.name
          // }
        }
      }).catch(error => {
        console.log('error==', JSON.stringify(error))
      })
    },
    initPlaceSearch() {
      let that = this
      AMap.plugin('AMap.Autocomplete', () => {
        let autoOptions = {city: '苏州市'}
        let autoComplete = new AMap.Autocomplete(autoOptions);
        autoComplete.search(that.keyWord, function (status, result) {
          console.log('result==', result)
          if (result.info === 'OK') {
            that.locationList = result.tips
          }
        })
      })
    },
  }
}
</script>

<style scoped lang="scss">
.content-search {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;

  .search {
    background: white;
    padding: 8px;
    display: flex;
    flex-direction: row;
    height: 38px;
    position: relative;
  }

  .search-input {
    flex: 1;
    background: #F4F4F4;
    border-width: 0;
    border-radius: 8px;
    padding: 0 28px 0 8px;
    outline: none;
  }
  .clear-img {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 54px;
    bottom: 19px;
  }

  .cnacelBtn {
    padding-left: 14px;
    font-size: 14px;
    line-height: 38px;
  }

  .pol-list {
    margin: 10px;
    flex: 1;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    img {
      height: 16px;
      object-fit: cover;
    }

    .pol-flag {
      display: flex;
      height: 44px;
      align-items: center;
      padding-left: 30px;
      border-bottom: 1px solid #EEEEEE;

      p {
        padding-left: 4px;
        color: #666666;
        font-size: 13px;
      }
    }

    .list {
      flex: 1;
      overflow-y: auto;

      .pol-row {
        display: flex;
        padding: 15px 10px;
        align-content: center;
        flex-direction: column;
        border-bottom: 1px solid #EEEEEE;

        .row-address {
          display: flex;
          align-items: center;

          .address-name {
            flex: 1;
            padding-left: 8px;
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
		  
		  .address-name span{
			  color: #999999;
			  font-size: 12px;
		  }
        }

        .address-intro {
          padding-left: 24px;
          width: 100%;
          box-sizing: border-box;
          color: #999999;
          font-size: 12px;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
