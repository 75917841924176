<template>
  <div class="navigation">
    <div class="person-center" @click="$emit('clickPerson')">
      <img :src="imgUrl" alt="">
      <p>个人中心</p>
    </div>
    <div class="itinerary message" v-if="true" @click="$emit('clickItinerary', info)">
      <p>我的行程({{ info.total_count }})</p>
      <div class="dot-count" v-show="info.message > 0">{{ info.message }}</div>
    </div>
    <div class="itinerary no-message" @click="$emit('clickItinerary', info)" v-else>
      <p>暂无行程</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data() {
    return {}
  },
  computed: {
    imgUrl() {
      // require('@/assets/checked_bg2.png')
      return require('@/assets/morentouxiang.png')
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {}
}
</script>

<style scoped lang="scss">
.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  .person-center {
    display: flex;
    flex-direction: column;
    height: 64px;
    width: 64px;
    justify-content: center;

    img {
      height: 32px;
      object-fit: contain;
    }

    p {
      font-size: 10px;
      color: #666666;
      text-align: center;
    }
  }

  .itinerary {
    height: 32px;
    width: 90px;
    border-radius: 4px;
    line-height: 32px;
    position: relative;
    border: 1px solid #DDDDDD;

    p {
      font-size: 12px;
      text-align: center;
    }

    .dot-count {
      position: absolute;
      top: -4px;
      right: -4px;
      width: 20px;
      height: 20px;
      background: #FF3B2F;
      border-radius: 10px;
      font-size: 10px;
      line-height: 20px;
      color: white;
      transform: scale(0.8);
    }
  }

  .message {
    p {
      color: #666666;
    }
  }

  .no-message {
    p {
      color: #CCCCCC;
    }
  }
}
</style>
