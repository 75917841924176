<!-- 用户端订单详情 -->
<template>
  <div class="home">
    <div class="map-contanier" id="container">
      <div style="display:none">
        <div id="myIcon" class="circleBox">
          <div class="circle1"></div>
          <div class="circle2"></div>
          <div class="circle3"></div>
          <div class="circle4"></div>
          <div class="circle5">
            <img class="circle-img" :src="imgBaseUrl+'dangqianweizhi1.png'">
          </div>
        </div>
      </div>
    </div>
    <!-- <img class="btn-close" :src="iconUrl" @click="handleClose"> -->
    <img class="btn-close" src="@/assets/btn_close.png" @click="handleClose">
    <component
        :is="currentComponent"
        :info="componentInfo"
        :messageNumber="unReadNumber"
        :isShow="isShow"
        @callBack="handleCallBack"
        @sureClick="handleSureClick">
    </component>
  </div>
</template>

<script>
	// STATE_MODE 状态  EVENT_MODEL  STATE_ORDER_MODE 订单状态
import {STATE_MODE, EVENT_MODEL, STATE_ORDER_MODE} from "../model/stateModel"
// 更改地址 SelectAddress √
import SelectAddress from "@/components/tyremend/select-address.vue"
// 呼叫师傅 CallDriver √
import CallDriver from "@/components/tyremend/call-driver.vue"
// 师傅已接单  DriverGetOrder √ 两种状态两种页面（去店铺，上门维修）
import DriverGetOrder from "@/components/tyremend/driver-get-order.vue"
// 师傅正在赶来 DriverGoing  √ (好评率：待加)
import DriverGoing from "@/components/tyremend/driver-going.vue"
// 师傅已到达 DriverArriver 
import DriverArriver from "@/components/tyremend/driver-arrive.vue"
// 师傅到达卸货点  
import DriverGoDestiantion from "@/components/tyremend/driver_go_destiantion.vue"

// 用户去支付 OrderPay  待修改 详细金额
import OrderPay from "@/components/tyremend/order-pay.vue"
// 用户已支付 FinishOrder
import FinishOrder from "@/components/tyremend/finish-order.vue"
// import DriverArriveDestiantion from "@/components/driver-arrive-destiantion.vue"
import store from '@/store'

export default {
  name: "Home",
  components: {
    SelectAddress,// 更改地址 
    CallDriver,// 呼叫师傅
    DriverGetOrder,// 师傅已接单
    DriverGoing,// 师傅正在赶来
    DriverArriver,// 师傅已到达
    DriverGoDestiantion,// 师傅到达卸货点 不需要
    OrderPay,// 用户去支付 {待定}
    FinishOrder,// 用户已支付
  },
  data() {
    return {
      fromObj: {
        from: '',
        lng: [],
        currentState: 0,
        startLng: [],
        endLng: [],
        currentLocation: [],
        currentLocationObj: {},
      },
      isSelect: false,
      selectPlaceItem: '',//请选择位置
      mapTimer: '',
      delayTimer: '',
      locationTimer: '',
      needUpdate: true,//需要更新
      eventState: 0,//事件状态
      map: null,
      mapState: '',
      iconUrl: '',
      positionPicker: null,//位置选择器
      dragPoint: [],
      currentComponent: '',
      componentInfo: '',
      tickets: '',
      orderDetailInfo: {},//订单详细信息
      orderSn: 'DS20211213143646515384',
      lineArr: [],
      totalLineArr: [],//订单轨迹经纬度
      angle: 0,
      currentPosition: [],//当前位置
      isOnce: false,        // 只加载一次
      imgBaseUrl: "http://res.hoopoeiot.cn/Sz_jgy_map/static/img/",
      unReadNumber: 0,
      polyline: {},
      carMarker: {},//车辆标记
      isFirst: true,
      count: 0,
      // selectLocation: {},
      isShow: true,        // 是否显示place
    }
  },
  created() {
    document.title = "地图选址"
	// this.getLocation()
  },
  mounted() {
    this.loadInit()
  },
  // 页面销毁
  destroyed() {
    this.handleDestroy()
  },
  methods: {
    handleDestroy() {
      this.isOnce = false
      clearInterval(this.mapTimer)
      this.map.destroy();
    },
    loadInit() {
      this.fromObj = this.$route.query
      console.log(this.fromObj)
      this.fromObj.currentState = this.$route.query['currentState'] || 0
	  this.fromObj.v = this.$route.query['v'] || 20220610
      this.fromObj.currentLocationObj = this.fromObj['location'] ? JSON.parse(decodeURIComponent(this.fromObj['location'])) : ''
      if (this.fromObj.currentState == 0) {
        let selectLocation = this.fromObj['address'] ?  JSON.parse(this.fromObj['address']) : ''
        if (selectLocation && Object.keys(selectLocation).length > 0) {
          this.isShow = false
        }
      }
	   store.state.$v = this.fromObj.v
      // console.log('this.fromObj.currentLocationObj=', JSON.stringify(this.fromObj.currentLocationObj))
      this.init().then(() => {
        this.getRealLocation()
      })
      this.getRealTimeOrder()
      this.getLocationTimer()
    },
    // 初始化
    async init(status = true) {

      // this.iconUrl = require(this.mapState === 'SELECT_ADDRESS' ? '@/assets/btn_close.png' : '@/assets/btn_fanhui.png')
      //当状态大于0的时候获取订单信息
	  // currentState 当前状态
      if (this.fromObj.currentState > 0) {
        await this.getOrderDetail()
        await this.getOrderLocus()
      }

      if (status == true || this.needUpdate == true) {
        this.mapState = STATE_MODE[this.fromObj.currentState]
        console.log(this.mapState)
        this.fromObj.lng = this.getLng()
        this.eventState = this.fromObj.from === 'start' ? EVENT_MODEL.CONFIRM_ADDRESS_STARE : EVENT_MODEL.CONFIRM_ADDRESS_End
        this.initMap()
        this.judgementState()
      }
    },
    //获取订单轨迹
    getOrderLocus() {
      return new Promise((resolve, reject) => {
        if ((this.fromObj.currentState === 5 || this.fromObj.currentState === 3) && (this.needUpdate || this.isFirst)) {
			let parms ={}
			parms.point_longitude = this.orderDetailInfo.point_longitude
			parms.point_latitude = this.orderDetailInfo.point_latitude
			parms.uninstall_longitude = this.orderDetailInfo.uninstall_longitude
			parms.uninstall_latitude = this.orderDetailInfo.uninstall_latitude
			// {'order_no': this.orderSn}
          this.$api.order.doOrdinaryCar(parms).then(res => {
				this.orderDetailInfo.duration = res.data.duration
				this.orderDetailInfo.distance = res.data.distance
				console.log(this.orderDetailInfo,res.data,'this.orderDetailInfo+++++++++++++++++++++++res');
				this.totalLineArr = res.data.polylines.map(item => {
				  return [item.longitude, item.latitude]
				})
			 // this.totalLineArr = arr.reverse()
            if (this.count > 0) {
              this.lineArr = this.totalLineArr
              this.currentPosition = this.totalLineArr[0]
              this.getRealLocation().then(res => {
                clearInterval(this.locationTimer)
                this.getLocationTimer()
              })

              resolve()
            } else {
              resolve()
            }
            this.isFirst = false
          }).catch(error => {

          })
        } else {
          resolve();
        }
      })


    },
    //获取汽车实时位置
    getRealLocation() {
      return new Promise((resolve, reject) => {
        this.$api.order.getCarLoc({'order_no': this.orderSn}).then(res => {
          if (res.ret === 0) {
            this.angle = res.data.direction
            this.lineArr = this.totalLineArr.filter((item, index) => index >= res.data.index)
			if(this.orderDetailInfo.device_model_id == 7){
				  // this.lineArr  = this.lineArr.reverse()
				// console.log(this.lineArr,"反转数组");
			}
			// console.log(this.totalLineArr,"this.totalLineArr");
			// console.log( this.lineArr,"获取汽车实时位置 this.lineArr");
			// console.log( this.polyline," this.polyline.setPath");
			console.log(this.fromObj,"获取汽车");
			// if()
            if (this.fromObj.currentState === 3 || this.fromObj.currentState === 5) {
              setTimeout(() => {
                this.polyline.setPath(this.lineArr)
                if (Object.keys(this.carMarker).length > 0) {
                  const position = [res.data.longitude, res.data.latitude]
                  this.carMarker.setPosition(position)
                } else {
                  this.currentPosition = [res.data.longitude, res.data.latitude]
                  this.drawCarLocation()
                }
              }, 500)
              //this.map.setFitView()
            }
            this.unReadNumber = res.data.num_unread
            resolve()
          }
        }).catch(error => {
          console.log(error)
        })
      })

    },
    getLocationTimer() {
      if (this.fromObj.currentState > 0) {
        if (this.locationTimer) {
          clearInterval(this.locationTimer)
        }
        this.locationTimer = setInterval(() => {
          this.getRealLocation()
        }, 5000)
      }
    },
	// 获取实时订单
    getRealTimeOrder() {
      this.count++;
      if (this.fromObj.currentState > 0) {
        if (this.mapTimer) {
          clearInterval(this.mapTimeout)
        }
        this.mapTimer = setInterval(() => {
          this.init(false)
        }, 10000)
      }
    },
    // 状态判断
    judgementState() {
      if (parseInt(this.fromObj.currentState) == 0) {
        document.title = "地图选址"
      } else {
        document.title = "订单详情"
      }
	  // if(this.fromObj){}
	  console.log(this.fromObj.currentState,"状态判断this.fromObj.currentState");
      switch (parseInt(this.fromObj.currentState)) {
        case 0:
          //地址选择
          // this.componentInfo = this.selectLocation
          this.currentComponent = 'SelectAddress'
          break;
        case 1:
          //this.componentInfo = {'price': 1212, 'name': '张师傅'}
          this.componentInfo = this.orderDetailInfo
          // 呼叫师傅
          this.currentComponent = "CallDriver"
          break;
        case 2:
          // 师傅已接单
          //this.componentInfo = {'price': 1212, 'name': '张师傅'}
          this.componentInfo = this.orderDetailInfo
          this.currentComponent = "DriverGetOrder"
          break;
        case 3:
          // 师傅正在赶来
          // this.componentInfo = {'price': 1212, 'name': '张师傅'}
          // this.componentInfo = this.orderDetailInfo
          // this.currentComponent = "DriverGoing"
          break;
        case 4:
          // 师傅已到达
          // this.componentInfo = {'price': 1212, 'name': '张师傅'}
          // this.componentInfo = this.orderDetailInfo
          // this.currentComponent = "DriverArriver"
          break;
        case 5:
			// 师傅正在前往
          // this.componentInfo = {'price': 1212, 'name': '张师傅', state: this.fromObj.currentState}
          this.componentInfo = this.orderDetailInfo
		  console.log(this.componentInfo,"this.componentInfothis.componentInfothis.componentInfo")
          this.currentComponent = "DriverGoDestiantion"
          break;
        case 6:
			//到达修车点
          // this.componentInfo = {'price': 1212, 'name': '张师傅', state: this.fromObj.currentState}
          this.componentInfo = this.orderDetailInfo
          this.currentComponent = "DriverGoDestiantion"
          break;
        case 7:
		// 待付款（待确认）
          this.componentInfo = this.orderDetailInfo
          this.currentComponent = "OrderPay"
          break;
        case 8:
		// 已完成 （去评价）
          this.componentInfo = this.orderDetailInfo
          this.currentComponent = "FinishOrder"
          break;
      }
    },
	getLocation() {
		const self = this
		AMap.plugin('AMap.Geolocation', function () {
		  var geolocation = new AMap.Geolocation({
			// 是否使用高精度定位，默认：true
			enableHighAccuracy: true,
			// 设置定位超时时间，默认：无穷大
			timeout: 10000,
		  })

		  geolocation.getCurrentPosition()
		  AMap.event.addListener(geolocation, 'complete', onComplete)
		  AMap.event.addListener(geolocation, 'error', onError)

		  function onComplete(data) {
			// data是具体的定位信息
			console.log('定位成功信息：', data)
		  }

		  function onError(data) {
			// 定位出错
			console.log('定位失败错误：', data)
			self.getLngLatLocation()
		  }
		})
	  },
	  getLngLatLocation() {
		AMap.plugin('AMap.CitySearch', function () {
		  var citySearch = new AMap.CitySearch()
		  citySearch.getLocalCity(function (status, result) {
			if (status === 'complete' && result.info === 'OK') {
			  // 查询成功，result即为当前所在城市信息
			  console.log('通过ip获取当前城市：',result)
			  //逆向地理编码
			  AMap.plugin('AMap.Geocoder', function() {
				var geocoder = new AMap.Geocoder({
				  // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
				  city: result.adcode
				})

				var lnglat = result.rectangle.split(';')[0].split(',')

				geocoder.getAddress(lnglat, function(status, data) {
				  if (status === 'complete' && data.info === 'OK') {
					// result为对应的地理位置详细信息
					console.log("getLngLatLocation",data)
				  }
				})
			  })
			}
		  })
		})
	  },
    //根据信息获取经纬度
    getLng() {
      if (this.fromObj.currentState == 0) {
        let address = this.fromObj.address
        if (address) {
          return this.getChooseAddressLng(address)
        }
      } else {
        let start = [this.orderDetailInfo.point_longitude, this.orderDetailInfo.point_latitude]
        let end = [this.orderDetailInfo.uninstall_longitude, this.orderDetailInfo.uninstall_latitude]
        this.fromObj.startLng = start
        this.fromObj.endLng = end
        return start
      }
      return this.getDefaultLng()
    },
    //获取已选地址的经纬度
    getChooseAddressLng(address) {
      try {
        return JSON.parse(decodeURIComponent(address))['position']
      } catch (e) {
        return this.getDefaultLng()
      }
    },
    //获取默认的经纬度
    getDefaultLng() {
      // 返回当前位置
      // return [this.fromObj.currentLocationObj['longitude'], this.fromObj.currentLocationObj['latitude']]
      return [120.610868, 31.329679];
    },
    // 关闭
    handleClose() {
      // console.log('close....')
      // this.myUni.navigateBack({delete: 99})
	  // 执行事件状态去订单列表
      this.eventState = EVENT_MODEL.TO_ORDER
      this.handleClick()
    },
    // 判断是否是小程序
    judgementMiniProgram() {
      if (!window.WeixinJSBridge || !WeixinJSBridge.invoke) {
        return document.addEventListener('WeixinJSBridgeReady', this.ready, false)
      } else {
        return this.ready()
      }
    },
    ready() {
      return window.__wxjs_environment === 'miniprogram'
    },
    // 反向传值 - uniapp
    handleClick(obj = {}) {
      console.log('value============', JSON.stringify(this.componentInfo))
      let params = {
        address: this.componentInfo.address,
        fullAddress: this.componentInfo.fullAddress,
        position: this.componentInfo.position
      }
		console.log(this.eventState < 2 ? params : '' ,"params")
      this.myUni.postMessage({
        data: {
          action: "message",
          address: this.eventState < 2 ? params : '',
          queryFrom: this.fromObj.from,
          state: this.eventState,
          obj: obj
        },
      });
	  
      console.log('judgementMiniProgram', this.judgementMiniProgram())
      if (this.judgementMiniProgram()) {
        this.myUni.navigateBack({delete: 99, flagPush: true})
      }
    },
    // 叫车 - 订单信息 - 点击事件
    handleOrderClick() {
      this.eventState = EVENT_MODEL.GET_ORDER_INFO
      this.handleClick()
    },
    // 叫车 - 取消订单
    handleCancel() {
		if(this.fromObj.currentState == 5){
			this.$message({
			  content: '确定取消订单吗,师傅赶来取消订单不退定金',
			  confirmText: '确定',
			  confirmColor: '#ff0',
			  showCancel: true,
			}).then((action) => {
			  //确定
			  this.eventState = EVENT_MODEL.CANCEL_ORDER
			  this.handleClick()
			}).catch((action) => {
			  //取消
			});
		} else {
			this.$message({
			  content: '确定取消订单吗',
			  confirmText: '确定',
			  confirmColor: '#ff0',
			  showCancel: true,
			}).then((action) => {
			  //确定
			  this.eventState = EVENT_MODEL.CANCEL_ORDER
			  this.handleClick()
			}).catch((action) => {
			  //取消
			});
		}
     
    },
	// 处理动作 返回程序
    handleAction(item) {
		console.log(item,"处理动作 返回程序");
      switch (item.value) {
        case 1:
		// 取消订单
          this.handleCancel()
          break;
        case 2:
		// 联系司机
          this.eventState = EVENT_MODEL.CALL_PHONE
          this.handleClick()
          break;
        case 3:
		// 发消息
          this.eventState = EVENT_MODEL.SEND_MESSAGE
          this.orderDetailInfo.num_unread = 0
          this.handleClick()
          break;
        case 4:
		// 联系客服
          this.eventState = EVENT_MODEL.CONTACT_KEFU
          this.handleClick()
          break;
        case 5:
		// 修改终点
          this.eventState = EVENT_MODEL.ALTER_END_PLACE
          // this.handleClick()
		  // this.alterEndPlace(item)
          break;
        case 6://去支付
          this.eventState = EVENT_MODEL.PAY_ORDER
          let obj = {
            invoice_type_id: item.invoice_type_id
          }
          this.handleClick(obj)
          break;
        case 7:
		// 再来一单
          // this.eventState = EVENT_MODEL.ANOTHER_ORDER
          // this.handleClick()
          break;
        case 8:
		// 去订单详情
          this.eventState = EVENT_MODEL.TO_ORDER_DETAIL
          this.handleClick()
          break;
		case 19:
		// 用户完成订单
			this.eventState = EVENT_MODEL.TO_EVALUATE
			this.handleClick()
			break;
		case 20:
		// 用户点击一键导航
			this.eventState = EVENT_MODEL.USER_ONE_KEY_NAVIGATION
			this.handleClick()
			break;
      }
    },
    // 查看订单
    checkOrder() {
      // console.log('.....查看订单')
      let params = {
        value: 8
      }
      this.handleAction(params)
    },
    // 修改终点
    alterEndPlace(val) {
      console.log('修改终点修改终点修改终点修改终点修改终点', val)
      let params = {}
      if (val) {
        params = {
          order_no: this.orderSn,
          uninstall_name: val.address,
          uninstall_address: val.fullAddress,
          uninstall_longitude: val.location.lng,
          uninstall_latitude: val.location.lat,
          tickets: this.fromObj.tickets ? this.fromObj.tickets : '885f405ad992a84d6c88ac67ac34aafac8905c64e979fd561b4eeee323c4b4da22217638be8ff9e43c22d491a0a520b444cb0244a8d5da03dcb230ed8336f75847efc4bacb9799bea87afc1dc3d31592ff1281e14cea463d12fd02aae8accd1273504ca23341a7db08de3fee75d488e108a1fdace78e45adfe97a92b959b8a9c66867e8478c7ded5'
        }
      }
      this.$api.order.updateEndDestination(params).then(res => {
        if (res.ret === 0) {
          // this.isOnce = false
          // this.init()
          this.handleDestroy()
          this.loadInit()
        } else {
          this.$message({
            content: res.msg,
            confirmText: '确定',
            confirmColor: '#ff0',
            showCancel: false,
          })
        }
      }).catch(error => {

      })
    },
	// 获取地址
    getReturnAddress(val) {
      // const fullAddressDataKey = ['province', 'city', 'district', 'township', 'street', 'streetNumber']
      console.log('=================', JSON.stringify(val))
      // let fullAddressData = this.getAddressComponentData(fullAddressDataKey)

      // let currentAddress = this.componentInfo.address

      // fullAddressData.forEach(item => {
      //   currentAddress = currentAddress.replace(item, '')
      // })

      // fullAddressData = fullAddressData.join('')
      // let fullAddressData = this.componentInfo.fullAddress
      // let currentAddress = this.componentInfo.currentAddress
      let {name, address} = val.regeocode.pois[0]
      return {
        fullAddress: address,
        address: name,
        position: [val.position.lng, val.position.lat],
      }

    },

    // getAddressComponentData($key) {
    //   return $key.map((item) => this.componentInfo.regeocode.addressComponent[item])
    // },
    // 拖拽获取value
    getAddress(val) {
      if (this.isSelect) {
        this.isSelect = false
        val = {
          regeocode: {
            pois: [
              {
                'name': this.selectPlaceItem.currentAddress,
                'address': this.selectPlaceItem.fullAddress,

              }
            ]
          },
          position: this.selectPlaceItem.location
        }
      }
      // this.componentInfo = val
      this.componentInfo = this.getReturnAddress(val)
      // this.componentInfo.currentAddress = obj.address
      // this.componentInfo.fullAddress = obj.fullAddress
      console.log('this.componentInfo === ', JSON.stringify(this.componentInfo))
    },
    // 数据回传
    handleCallBack(val, isSelect, item) {
      this.isSelect = isSelect
      this.selectPlaceItem = val
      console.log('callBack数据回传handleCallBack', val)
      let arr = Object.keys(val.location)
      if (arr.length > 0) {
        this.map.setCenter([val.location.lng, val.location.lat])
        this.componentInfo = val
      } else {
        this.$message({
          content: '请选择具体地址',
          confirmText: '确定',
          confirmColor: '#ff0',
          showCancel: false,
        }).then((action) => {
          //确定
        }).catch((action) => {
          //取消
        });
      }
    },
    // 子组件传值
    handleSureClick(state = null, item) {
      // state => 0: 选址地址-确定
	  console.log(state,item,"子组件传值state,item");
      switch (state) {
        case 0:   //选址地址-确定
          this.handleClick(item)
          break
        case 1:     // 呼叫司机 - 点击订单信息
          this.handleOrderClick()
          break
        case 2:       // 呼叫司机  - 取消订单
          this.handleCancel()
          break
        case 3:     // 操作事件
          this.handleAction(item)
          break
        case 4:
		// 修改终点
          this.alterEndPlace(item)
          break
        case 5:
          // 查看订单
          this.checkOrder()
          break;
        case 99:
          this.handleClose()
          console.log('1212221')
          break
      }
    },
    // 初始化地图插件
    initMap() {
      // 初始化地图
      this.map = new AMap.Map('container', {
        center: this.fromObj.lng,
        // viewMode: '3D',
        zoom: 16,//级别
        scrollWheel: false,
        dragEnable: true,
        doubleClickZoom: true,
        touchZoom: true,
        resizeEnable: true,
        mapStyle: "amap://styles/macaron"
      });

      if (this.fromObj.currentState == 0) {
        this.map.on('zoomstart', this.mapZoomstart);
        this.map.on('zoomend', this.mapZoomend);
      }


      this.mapLayer = new AMap.LabelsLayer({
        zooms: [3, 20],
        zIndex: 1000,
        // collision: false,
        // 设置 allowCollision：true，可以让标注避让用户的标注
        allowCollision: false,
      });
      this.loadMapUI()
    },
    // 加载mapUI
    loadMapUI() {
      let that = this
      let param
	  
      // console.log('mapState', that.mapState)
      AMapUI.loadUI(['misc/PositionPicker', 'overlay/SimpleMarker', 'overlay/SimpleInfoWindow'], function (PositionPicker, SimpleMarker, SimpleInfoWindow) {
		  console.log(that.mapState,that.fromObj,"加载mapUI that.mapState,that.fromObj")
        switch (that.mapState) {
		
          case 'SELECT_ADDRESS':          //选址
            // that.positionPicker = PositionPicker
            that.loadPositionPicker(PositionPicker)
            break;
          case 'CALL_DRIVER':            // 叫车
            that.loadSimpMarker(SimpleMarker, true)
            param = {
              // content: "<p class='map-define-content'>已呼叫<span class='content-text-orange'>1</span>位司机</p>",
			  content: "<p class='map-define-content'>正在呼叫师傅</p>",
              offset: new AMap.Pixel(15, -25)
            }
            that.settingSimpleInfoWindow(SimpleInfoWindow, param)
            // that.addCurrentLocation()
            break;

          case 'DRIVER_GET_ORDER': //师傅已接单
            that.loadSimpMarker(SimpleMarker)
            param = {
              content: `<p class='map-define-content'>${that.orderDetailInfo.point_name}</p>`,
              offset: new AMap.Pixel(10, -25)
            }
			// if(this.orderDetailInfo.device_model_id == 8){
			// 	param.content = `<p class='map-define-content'>${that.orderDetailInfo.uninstall_name}</p>`
			// }
            that.settingSimpleInfoWindow(SimpleInfoWindow, param)
            break;
          case 'DRIVER_GOING':            // 路线规划
            that.loadPlugin()
            break;
          case 'DRIVER_ARRIVE':
            // that.loadSimpMarker(SimpleMarker)
            that.addMark({'lng': that.fromObj.startLng[0], 'lat': that.fromObj.startLng[1]})
            param = {
              content: `<p class='map-define-content'>${that.orderDetailInfo.point_name}</p>`,
              offset: new AMap.Pixel(10, -25)
            }
            that.settingSimpleInfoWindow(SimpleInfoWindow, param)
            break;
          case 'DRIVER_GO_DESTINATION':
            that.loadPlugin()
            break
          case 'DRIVER_ARRIVE_DESTINATION':
            that.addMark({'lng': that.fromObj.endLng[0], 'lat': that.fromObj.endLng[1]})
            param = {
              content: `<p class='map-define-content'>${that.orderDetailInfo.uninstall_name}</p>`,
              offset: new AMap.Pixel(48, -10)
            }
            that.settingSimpleInfoWindow(SimpleInfoWindow, param)
            break;
          case 'FINISH_ORDER':

            // that.addMark({'lng': that.fromObj.endLng[0], 'lat': that.fromObj.endLng[1]})
            // param = {
            //   content: `<p class='map-define-content'>${that.orderDetailInfo.uninstall_name}</p>`,
            //   offset: new AMap.Pixel(48, -10)
            // }
            // that.settingSimpleInfoWindow(SimpleInfoWindow, param)
            that.setLabelMarker()
            break;
          default:
            console.log('无订单')

        }
      })
    },
    //设置图层
    setLabelMarker() {
      let markers = []
      this.mapLayer.add(markers);
      // 图层添加到地图
      this.map.add(this.mapLayer);
      const icon = {
        // 图标类型，现阶段只支持 image 类型
        type: 'image',
        // 图片 url
        image: this.imgBaseUrl + 'dangqianweizhi1.png',
        // 图片尺寸
        size: [48, 48],
        // 图片相对 position 的锚点，默认为 bottom-center
        anchor: 'center',
      };

      const mapData = [
        {
          name: '自提点1',
          position: [this.fromObj.endLng[0], this.fromObj.endLng[1]],
          zooms: [10, 20],
          opacity: 1,
          zIndex: 10,
          fold: true,
          icon,
          text: {
            // 要展示的文字内容
            content: this.orderDetailInfo.uninstall_name,
            // 文字方向，有 icon 时为围绕文字的方向，没有 icon 时，则为相对 position 的位置
            direction: 'right',
            // 在 direction 基础上的偏移量
            offset: [-10, 15],
            // 文字样式
            style: {
              // 字体大小
              fontSize: 16,
              // 字体颜色
              fillColor: '#499D82',
              //
              strokeColor: '#fff',
              strokeWidth: 2,
              fold: true,
              padding: '2, 5',
            }
          }
        }
      ]
      for (let i = 0; i < mapData.length; i++) {
        let curData = mapData[i];
        curData.extData = {
          index: i
        };

        let labelMarker = new AMap.LabelMarker(curData);

        markers.push(labelMarker);

      }
      // 将 marker 添加到图层
      this.mapLayer.add(markers);
      this.map.setFitView(null, false, [100, 150, 10, 10]);
    },
    // 设置窗体文本
    settingSimpleInfoWindow(SimpleInfoWindow, param) {
      let infoWindow = new SimpleInfoWindow({
        infoBody: param.content,
        offset: param.offset
      })
      infoWindow.open(this.map, this.map.getCenter())
    },
    // 设置图标
    loadSimpMarker(SimpleMarker, isShowRipple = false) {
      //  设置图标
	  console.log(SimpleMarker,"设置图标");
      let iconStyle = {}
      if (isShowRipple) {
        iconStyle = document.getElementById('myIcon')
      } else {
        iconStyle = {
          // src: this.imgBaseUrl + 'dangqianweizhi1.png',
          src: this.imgBaseUrl + 'butaiCar.png',
          style: {
            width: '35px',
            height: '35px'
          },
        }
      }
      new SimpleMarker({
        iconStyle,
        map: this.map,
        position: this.fromObj.lng,
      })
    },
    // 加载positionPicker - 拖动选址
    loadPositionPicker(PositionPicker) {
      let iconUrl = this.imgBaseUrl + 'dangqianweizhi1.png'
      if (this.eventState === 0) {
        // 开始
        iconUrl = this.imgBaseUrl + 'zhuang2.png'
      } else {
        // 结束
        iconUrl = this.imgBaseUrl + 'xie2.png'
      }
      let that = this
      this.positionPicker = new PositionPicker({
        mode: 'dragMap',
        map: that.map,
        iconStyle: { //自定义外观
          url: iconUrl,
          ancher: [24, 40],
          size: [35, 35]
        }
      });

      this.positionPicker.on('success', function (positionResult) {
        console.log('positionResult=====', positionResult)
        let val = {}
        that.getAddress(positionResult)

      })
      console.log('lng', this.fromObj)
      // if (this.fromObj.lng) {
      //   positionPicker.start(this.fromObj.lng);
      // } else {
      //   if (this.fromObj.currentLocationObj['longitude'] && this.fromObj.currentLocationObj['latitude']) {
      //     positionPicker.start([this.fromObj.currentLocationObj['longitude'], this.fromObj.currentLocationObj['latitude']])
      //   } else {
      //     positionPicker.start(this.fromObj.lng);
      //   }
      // }
      // this.positionPicker.start(this.fromObj.lng);
      // positionPicker.stop();
      this.startPositionPickerLocation()
    },
    // 开启定位
    startPositionPickerLocation() {
      if (this.fromObj.lng) {
        this.positionPicker.start(this.fromObj.lng);
      } else {
        if (this.fromObj.currentLocationObj['longitude'] && this.fromObj.currentLocationObj['latitude']) {
          this.positionPicker.start([this.fromObj.currentLocationObj['longitude'], this.fromObj.currentLocationObj['latitude']])
        } else {
          this.positionPicker.start(this.fromObj.lng);
        }
      }
    },
    // 开始缩放
    mapZoomstart() {
      console.log('drag end.....')
      // this.positionPicker.stop();
      // that.positionPicker.start(that.componentInfo.position)
    },
    // 结束缩放
    mapZoomend() {
      let that = this
      // setTimeout(() => {
      //   console.log('drag start.....')
      that.positionPicker.start(that.componentInfo.position)
      // }, 0);
    },
    // 异步同时加载多个插件
    loadPlugin() {
      this.drawRoute()
    },
	// 绘制汽车位置
    drawCarLocation() {
      console.log(this.map)
      this.carMarker = new AMap.Marker({
        map: this.map,
        position: this.currentPosition,
        icon:new AMap.Icon({
          image: "https://jx.hoopoeiot.cn/res/hoepaas/logo/truck_green2_small.png",
          size: new AMap.Size(24, 48),  //图标所处区域大小
          imageSize: new AMap.Size(24,48) //图标大小
        }),
        // icon: "https://jx.hoopoeiot.cn/res/hoepaas/logo/truck_green2_small.png",
        offset: new AMap.Pixel(-26, -13),
        autoRotation: true,
        angle: this.angle,
      });
    },
    //绘制路径
    drawRoute(route = '') {
      console.log(this.lineArr,"绘制路径this.lineArr")
      this.polyline = new AMap.Polyline({
        map: this.map,
        path: this.lineArr,
        showDir: true,
        strokeColor: "#56B266",  //线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6,      //线宽
        // strokeStyle: "solid"  //线样式
      });
      let endMarker = new AMap.Marker({
        map: this.map,
        position: this.lineArr[this.lineArr.length - 1],
        // icon: this.imgBaseUrl + 'zhong.png',
        offset: new AMap.Pixel(-24, -48),
        autoRotation: true,
      });
      let qiMarker = new AMap.Marker({
        map: this.map,
        position: this.lineArr[0],
        // icon: this.imgBaseUrl + 'qi.png',
        offset: new AMap.Pixel(-24, -48),
        autoRotation: true,
      });
		if(this.orderDetailInfo.device_model_id == 7){
			this.settingMarket(qiMarker, this.orderDetailInfo.point_name, 'qi.png')
			this.settingMarket(endMarker, this.orderDetailInfo.uninstall_name, 'zhong.png')
		}else{
			this.settingMarket(qiMarker, this.orderDetailInfo.point_name, 'qi.png')
			this.settingMarket(endMarker, this.orderDetailInfo.uninstall_name, 'zhong.png')
		}
      
      // 调整视野达到最佳显示区域
      if (!this.isOnce) {
        this.map.setFitView()
      }
      this.isOnce = true
      // }
    },
// 设置终点、起点
    settingMarket(market, content, imgName) {
      let markerContent = document.createElement("div")
      let markerSpan = document.createElement("span")
      markerSpan.className = 'adaptive-width marker'
      markerSpan.innerHTML = content
      markerContent.appendChild(markerSpan)

      let markerImg = document.createElement("img")
      markerImg.className = "markerlnglat"
      markerImg.src = this.imgBaseUrl + imgName
      markerContent.appendChild(markerImg)


      market.setContent(markerContent)
    },
    //解析地图路径
    parseRouteToPath(route) {
      let path = []

      for (let i = 0, l = route.steps.length; i < l; i++) {
        let step = route.steps[i]

        for (let j = 0, n = step.path.length; j < n; j++) {
          path.push(step.path[j])
        }
      }

      return path
    },
    // 标点 - mark
    addMark(item) {
		console.log(item,"标点标点标点");
      let marker = new AMap.Marker({
        icon: this.imgBaseUrl + 'dangqianweizhi1.png',
        position: new AMap.LngLat(item.lng, item.lat)
      })
      this.map.add(marker)
      this.addFitView()
    },
    // 当前位置
    addCurrentLocation() {
      let that = this
      // 设置图标大小
      let settingIcon = new AMap.Icon({
        image: that.imgBaseUrl + 'dangqianweizhi.png',
        size: new AMap.Size(35, 35),
        imageSize: new AMap.Size(35, 35),
        imageOffset: new AMap.Pixel(0, 0)
      })
      console.log('=======', that.fromObj.lng)
      this.delayTimer = setTimeout(() => {
        let marker = new AMap.Marker({
          icon: settingIcon,
          position: new AMap.LngLat(that.fromObj.lng[0], that.fromObj.lng[1])
        })
        that.map.add(marker)
        that.addFitView()
      }, 0);
    },
    // 自动适配到合适的视野范围
    addFitView() {
      this.map.setFitView();
    },
    //获取订单信息
    getOrderDetail() {
      return new Promise((resolve, reject) => {
        this.orderSn = this.fromObj.orderSn ? this.fromObj.orderSn : 'DS20211213143646515384'
        this.tickets = this.fromObj.tickets ? this.fromObj.tickets : '885f405ad992a84d6c88ac67ac34aafac8905c64e979fd561b4eeee323c4b4da22217638be8ff9e43c22d491a0a520b444cb0244a8d5da03dcb230ed8336f75847efc4bacb9799bea87afc1dc3d31592ff1281e14cea463d12fd02aae8accd1273504ca23341a7db08de3fee75d488e108a1fdace78e45adfe97a92b959b8a9c66867e8478c7ded5'
        store.state.$tickets = this.tickets
        this.$api.order.getOrderDetail({'order_no': this.orderSn}).then(res => {
			console.log(res.data,"获取订单信息");
          if (res.ret === 0) {
            this.orderDetailInfo = Object.assign({}, this.orderDetailInfo, res.data)
            let currentState = STATE_ORDER_MODE[this.orderDetailInfo.order_status] ? STATE_ORDER_MODE[this.orderDetailInfo.order_status] : 0
            currentState == this.fromObj.currentState ? this.needUpdate = false : this.needUpdate = true
            if (this.needUpdate) {
              this.carMarker = {}
            }
            if (currentState == 8) {
              clearInterval(this.mapTimer)
              clearInterval(this.locationTimer)
            }
            this.fromObj.currentState = currentState
            resolve()
          }
        }).catch(error => {

        })
      })

    }
  },
};
</script>
<style lang="scss">

@import '@/style/map.scss';

</style>
