<template>
  <div class="order-state-des">
    <p class="des-title">{{title}}</p>
	<div class="state-des-tips" @click="userNavigation" v-if="info == 8">
		一键导航
	</div>
    <div class="des-slot">
      <slot></slot>
	  <!-- <span>预计<span class="span-orange">{{info.duration}}</span>分钟到达</span> -->
    </div>
	<!-- <div class="span-orange">预计{{info.duration}}分钟到达</div> -->
  </div>
</template>

<script>
export default {
  props: ['title', 'showTips','info'],
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
	userNavigation() {
	let	item = {
		icon: "/img/dianhua.5caaef5f.png",
		name: "一键导航",
		value: 20
		}
	  this.$emit('getLocation', item)
	}
  }
}
</script>

<style scoped lang="scss">
.order-state-des {
	
  padding: 0 15px;
  .des-title {
    font-size: 20px;
    height: 28px;
    line-height: 28px;
    font-weight: 600;
	display: inline-block;
	float: left;
  }
  .state-des-tips {
		float: right;
		border: 1px solid #EE690D;
		width: 25%;
		height: 30px;
		padding: 0 5px;
		line-height: 30px;
		color: #EE690D;
  // 		.tips-content {
		// display: inline-block;
  // 		  border-color: #EE690D;
  // 		  border-radius: 4px;
  // 		  padding-left: 5px;
  // 		  font-size: 14px;
  // 		  font-weight: 500;
  // 		  color: #EE690D;
  // 		}
  }
  .des-slot {
    padding: 5px 0 10px 0;
    span {
      width: 100%;
      font-size: 12px;
      color: #999999;
      line-height: 20px;
      font-weight: 500;
      text-align: left;
      display: inline-block;
    }
    .span-orange {
      color: #EE690D;
      width: auto;
    }
  }
  
}

</style>
